import { useState, useMemo, useCallback } from 'react';
import { Stack, Box, Drawer, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon } from '@mui/icons-material';

import { useParams, useLoaderData, useLocation } from 'react-router-dom';
import { useInfiniteQuery, keepPreviousData } from '@tanstack/react-query';
import { queryData } from 'utils/http.js';

import { customScrollbar } from 'config/customCSS.js';

import cateLv1Json from 'data/cateLv1.json';
import cateLv2Json from 'data/cateLv2.json';
import cateLv3Json from 'data/cateLv3.json';

import {
    CustomHelmet,
    CustomLoadingModal,
    CustomMasonry,
    CustomButton,
} from 'UI';

import SectionBreadcrumbs from '../../layout/section/SectionBreadcrumbs.jsx';
import Page404 from '../404/Page404.jsx';

import ProductCard from './ProductCard.jsx';
import FilterSidebar from './subComponents/FilterSidebar.jsx';
import PageTitle from './subComponents/PageTitle.jsx';
import Header from './subComponents/Header.jsx';
import CategorySection from './subComponents/CategorySection.jsx';
import ActiveFilterBox from './subComponents/ActiveFilterBox.jsx';
import NoFoundData from './subComponents/NoFoundData.jsx';

import PageProductSkeleton from './skeletons/PageProductSkeleton.jsx';

let CATEGORIES = [...cateLv1Json, ...cateLv2Json, ...cateLv3Json];
const PAGE_SIZE = 12;

export default function PageProductList({ ...otherProps }) {
    const theme = useTheme();
    const biggerSM = useMediaQuery(theme.breakpoints.up('sm')); // >=600
    let location = useLocation();
    const { cateLv1Path, subCategoryPath } = useParams();
    const { cateLv1Data, subCateData } = useLoaderData();

    let currentLevel = subCategoryPath ? subCateData.level : 1;
    let breadcrumbsData = [
        {
            to: `/${cateLv1Path}`,
            name: cateLv1Data.name,
            disabled: currentLevel === 1,
        },
    ];
    if (currentLevel === 2) {
        breadcrumbsData.push({
            to: `/${cateLv1Path}/${subCategoryPath}`,
            name: subCateData.name,
            disabled: true,
        });
    } else if (currentLevel === 3) {
        const parentCategory = CATEGORIES.find(
            (item) => item.name === subCateData.parentName
        );
        breadcrumbsData.push(
            {
                to: `/${cateLv1Path}${parentCategory.slug}`,
                name: subCateData.parentName,
            },
            {
                to: `/${cateLv1Path}/${subCategoryPath}`,
                name: subCateData.name,
                disabled: true,
            }
        );
    }

    let cateTitle =
        (currentLevel === 1 ? subCateData?.name : subCateData?.name) || '';

    const [manuNameFilter, setManuNameFilter] = useState([]);
    const [manuNationFilter, setManuNationFilter] = useState([]);
    const [administrationFilter, setAdministrationFilter] = useState([]);
    const [dosageFormFilter, setDosageFormFilter] = useState([]);
    const [prescriptionFilter, setPrescriptionFilter] = useState([]);
    const [prodCateFilter, setProdCateFilter] = useState([]);
    const [isCollapsed, setIsCollapsed] = useState({
        manuName: false,
        manuNation: false,
        administration: false,
        dosageForm: false,
        prescription: false,
        prodCate: false,
    });
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [viewType, setViewType] = useState('vertical');

    const {
        data,
        isLoading, // True only on the initial load.
        isFetching, // True when fetching new data (subsequent requests).
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: [
            'product-item',
            {
                cateLv1Path,
                subCategoryPath,
                administration: administrationFilter,
                dosageForm: dosageFormFilter,
                manuName: manuNameFilter,
                manuNation: manuNationFilter,
                prescription: prescriptionFilter,
                prodCate: prodCateFilter,
            },
        ],
        queryFn: ({ pageParam = 0, signal }) =>
            queryData({
                signal,
                filterModel: [
                    { field: 'manuName', value: manuNameFilter },
                    {
                        field: 'manuNation',
                        value: manuNationFilter,
                    },
                    {
                        field: 'administration',
                        value: administrationFilter,
                    },
                    {
                        field: 'dosageForm',
                        value: dosageFormFilter,
                    },
                    {
                        field: 'prescription',
                        value: prescriptionFilter,
                    },
                    {
                        field: 'prodCate',
                        value: prodCateFilter,
                    },
                ],
                pageSize: PAGE_SIZE,
                page: pageParam + 1,
                apiUrl: `/product-item?cateLv1Path=${cateLv1Path}&subCategoryPath=${subCategoryPath}&type=1`,
            }),
        getNextPageParam: (lastPage, allPages) => {
            const morePagesExist = lastPage.data.length === PAGE_SIZE;
            if (!morePagesExist) return undefined;
            return allPages.length;
        },
        placeholderData: keepPreviousData,
        staleTime: 30000,
        retry: 0,
        enabled: !!cateLv1Data,
    });

    const mergedProducts = data?.pages?.flatMap((page) => page.data) || [];
    const totalProducts = parseInt(data?.pages?.[0]?.totalCount || 0);

    const activeFilterCount = useMemo(() => {
        return Number(
            manuNameFilter.length +
                manuNationFilter.length +
                administrationFilter.length +
                dosageFormFilter.length +
                prescriptionFilter.length +
                prodCateFilter.length
        );
    }, [
        administrationFilter.length,
        dosageFormFilter.length,
        manuNameFilter.length,
        manuNationFilter.length,
        prescriptionFilter.length,
        prodCateFilter.length,
    ]);
    const resetFilter = useCallback(() => {
        setManuNameFilter([]);
        setManuNationFilter([]);
        setAdministrationFilter([]);
        setDosageFormFilter([]);
        setPrescriptionFilter([]);
        setProdCateFilter([]);
    }, []);

    const dataFilter = useMemo(() => {
        return {
            prodCate: {
                label: 'Nhóm sản phẩm',
                options: data?.pages?.[0]?.prodCates || [],
                checkList: prodCateFilter,
                onCheck: setProdCateFilter,
                isCollapsed: isCollapsed.prodCate,
                onCollapse: () => {
                    setIsCollapsed((prev) => {
                        let updatedValue = { ...prev };
                        updatedValue.prodCate = !updatedValue.prodCate;
                        return updatedValue;
                    });
                },
            },
            manuName: {
                label: 'Nhà sản xuất',
                options: data?.pages?.[0]?.manufactures || [],
                checkList: manuNameFilter,
                onCheck: setManuNameFilter,
                isCollapsed: isCollapsed.manuName,
                onCollapse: () => {
                    setIsCollapsed((prev) => {
                        let updatedValue = { ...prev };
                        updatedValue.manuName = !updatedValue.manuName;
                        return updatedValue;
                    });
                },
            },
            manuNation: {
                label: 'Nước sản xuất',
                options: data?.pages?.[0]?.nations || [],
                checkList: manuNationFilter,
                onCheck: setManuNationFilter,
                isCollapsed: isCollapsed.manuNation,
                onCollapse: () => {
                    setIsCollapsed((prev) => {
                        let updatedValue = { ...prev };
                        updatedValue.manuNation = !updatedValue.manuNation;
                        return updatedValue;
                    });
                },
            },
            prescription: {
                label: 'Loại sản phẩm',
                options: data?.pages?.[0]?.prescriptions || [],
                checkList: prescriptionFilter,
                onCheck: setPrescriptionFilter,
                isCollapsed: isCollapsed.prescription,
                onCollapse: () => {
                    setIsCollapsed((prev) => {
                        let updatedValue = { ...prev };
                        updatedValue.prescription = !updatedValue.prescription;
                        return updatedValue;
                    });
                },
            },
            administration: {
                label: 'Đường dùng',
                options: data?.pages?.[0]?.administrations || [],
                checkList: administrationFilter,
                onCheck: setAdministrationFilter,
                isCollapsed: isCollapsed.administration,
                onCollapse: () => {
                    setIsCollapsed((prev) => {
                        let updatedValue = { ...prev };
                        updatedValue.administration =
                            !updatedValue.administration;
                        return updatedValue;
                    });
                },
            },
            dosageForm: {
                label: 'Dạng bào chế',
                options: data?.pages?.[0]?.dosageForms || [],
                checkList: dosageFormFilter,
                onCheck: setDosageFormFilter,
                isCollapsed: isCollapsed.dosageForm,
                onCollapse: () => {
                    setIsCollapsed((prev) => {
                        let updatedValue = { ...prev };
                        updatedValue.dosageForm = !updatedValue.dosageForm;
                        return updatedValue;
                    });
                },
            },
        };
    }, [
        administrationFilter,
        data?.pages,
        dosageFormFilter,
        isCollapsed.administration,
        isCollapsed.dosageForm,
        isCollapsed.manuName,
        isCollapsed.manuNation,
        isCollapsed.prescription,
        isCollapsed.prodCate,
        manuNameFilter,
        manuNationFilter,
        prescriptionFilter,
        prodCateFilter,
    ]);

    if ((cateLv1Path && !cateLv1Data) || (subCategoryPath && !subCateData)) {
        return <Page404 />;
    }

    if (isLoading) {
        return <PageProductSkeleton />;
    }

    return (
        <>
            <CustomHelmet
                title={
                    (cateTitle ? cateTitle : cateLv1Data?.name) +
                    ' chính hãng, giá tốt - Nhà thuốc Khánh Trang'
                }
                description={
                    'Mua ' +
                    cateTitle.toLowerCase() +
                    ' chính hãng, giá tốt tại nhà thuốc Khánh Trang'
                }
                slug={'https://nhathuockhanhtrang.com.vn' + location.pathname}
            />
            <Stack sx={{ maxWidth: 1260, mx: 'auto', p: { xs: 0, sm: 2 } }}>
                <CustomLoadingModal isLoading={isFetching} />
                <Stack
                    spacing={1}
                    mt={1}
                    pl={{ xs: 0, sm: 2, lg: 0 }}
                    pr={{ xs: 0, sm: 2, lg: 3 }}>
                    <Stack pl={{ xs: 2, sm: 0 }} spacing={1}>
                        <SectionBreadcrumbs breadcrumbsData={breadcrumbsData} />
                        <PageTitle
                            cateTitle={cateTitle}
                            totalProducts={totalProducts}
                        />
                    </Stack>
                    <CategorySection
                        cateLv1Path={cateLv1Path}
                        subCategoryPath={subCategoryPath}
                        data={CATEGORIES}
                        currentLevel={currentLevel}
                    />
                </Stack>
                <Stack
                    direction="row"
                    alignItems="stretch"
                    spacing={{ xs: 0, lg: 0.5 }}
                    sx={{ pt: 2 }}>
                    <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                        <Box
                            sx={{
                                position: '-webkit-sticky',
                                position: 'sticky',
                                top: 10,
                                maxHeight: '97vh',
                                borderRadius: '10px',
                                ...customScrollbar,
                            }}>
                            <FilterSidebar dataFilter={dataFilter} />
                        </Box>
                    </Box>
                    <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
                        <Drawer
                            open={isDrawerOpen}
                            onClose={() => setIsDrawerOpen(false)}>
                            <FilterSidebar dataFilter={dataFilter} />
                        </Drawer>
                    </Box>
                    <Box width="100%">
                        <Box
                            mx={{ xs: 0, sm: 2 }}
                            mb={{ xs: 1.5, sm: 0 }}
                            sx={{
                                backgroundColor: {
                                    xs: 'white',
                                    lg: 'transparent',
                                },
                                borderRadius: {
                                    xs: '0px',
                                    sm: '10px',
                                },
                            }}>
                            <Header
                                viewType={viewType}
                                setViewType={setViewType}
                                setIsDrawerOpen={setIsDrawerOpen}
                            />
                            <ActiveFilterBox
                                activeFilterCount={activeFilterCount}
                                dataFilter={dataFilter}
                                resetFilter={resetFilter}
                            />
                        </Box>
                        {!isFetching && mergedProducts.length === 0 && (
                            <NoFoundData
                                activeFilterCount={activeFilterCount}
                                resetFilter={resetFilter}
                            />
                        )}
                        {mergedProducts.length > 0 && (
                            <CustomMasonry
                                spacing={biggerSM ? 2 : 0.75}
                                elevation={1}
                                id="product-item-custom-masonry"
                                columns={
                                    viewType === 'vertical'
                                        ? { xs: 2, lg: 3, xl: 4 }
                                        : { xs: 1, md: 2, lg: 2, xl: 2 }
                                }
                                items={mergedProducts.map((prodData) => ({
                                    id: prodData._id,
                                    content: (
                                        <ProductCard
                                            prodData={prodData}
                                            viewType={viewType}
                                        />
                                    ),
                                }))}
                            />
                        )}
                        {hasNextPage &&
                        Number(totalProducts - mergedProducts.length) > 0 ? (
                            <Stack>
                                <CustomButton
                                    id="see-more-products-1-custom-button"
                                    variant="base"
                                    color="black"
                                    onClick={fetchNextPage}
                                    startIcon={
                                        <KeyboardDoubleArrowDownIcon fontSize="small" />
                                    }>
                                    {isFetchingNextPage
                                        ? 'Đang tải...'
                                        : `Xem thêm ${Number(
                                              totalProducts -
                                                  mergedProducts.length
                                          ).toLocaleString()} sản phẩm`}
                                </CustomButton>
                            </Stack>
                        ) : (
                            totalProducts > PAGE_SIZE && (
                                <Stack
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="center">
                                    <Typography fontWeight={500}>
                                        Bạn đã xem hết danh sách
                                    </Typography>
                                    {activeFilterCount > 0 && (
                                        <CustomButton
                                            id="clear-filters-custom-button"
                                            onClick={() => {
                                                setManuNameFilter([]);
                                                setManuNationFilter([]);
                                            }}
                                            color="rgb(0,0,139,0.8)"
                                            styleProps={{
                                                width: 170,
                                                fontSize: 16,
                                                fontWeight: 500,
                                                borderRadius: '20px',
                                            }}>
                                            Xóa tất cả bộ lọc
                                        </CustomButton>
                                    )}
                                </Stack>
                            )
                        )}
                    </Box>
                </Stack>
            </Stack>
        </>
    );
}

export const loader = ({ params }) => {
    let { cateLv1Path, subCategoryPath } = params;
    const foundCategory = cateLv1Path
        ? CATEGORIES.find((item) => item.slug === '/' + cateLv1Path)
        : null;
    const foundSubCategory = subCategoryPath
        ? CATEGORIES.find((item) => item.slug === '/' + subCategoryPath)
        : null;
    return { cateLv1Data: foundCategory, subCateData: foundSubCategory };
};
