import { useState, useRef } from 'react';

import { Box } from '@mui/material';
import {
    Inventory as InventoryIcon,
    Store as StoreIcon,
    Storage as StorageIcon,
} from '@mui/icons-material';

import { useLocation, useNavigate } from 'react-router-dom';

import breadcrumbNameMapping from 'data/breadcrumbNameMapping.json'

import { CustomMenu, CustomTabs, CustomButton } from 'UI';

export default function AdminNavbar({ ...props }) {
    const navigate = useNavigate();

    let containerRef = useRef(null);

    const location = useLocation().pathname;
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuTabItem, setMenuTabItem] = useState([]);

    //only render in desktop screen

    const customButtonProps = {
        variant: 'base',
        // size: 'large',
        styleProps: { minWidth: 0, pl: 0.5 },
        component: 'div',
        'aria-controls': Boolean(anchorEl) ? 'aria-controls' : undefined,
        'aria-haspopup': 'true',
        'aria-expanded': Boolean(anchorEl) ? 'true' : undefined,
    };
    let sx = {
        minWidth: 0,
        fontWeight: 500,
        fontSize: 16,
        position: 'relative',
        textTransform: 'initial',
        color: 'darkblue',
        '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 5,
            left: 0,
            width: '100%',
            height: '2px',
            backgroundColor: 'darkblue',
            transform: 'scaleX(0)',
            transformOrigin: 'bottom right',
            transition: 'transform 0.3s ease-in-out',
        },
        '&:hover::after': {
            transform: 'scaleX(1)',
            transformOrigin: 'bottom left',
        },
    };
    return (
        <Box
            ref={containerRef}
            sx={{
                width: '100%',
                backgroundColor: 'white',
            }}>
            <CustomTabs
                id="custom-admin-navbar"
                value={false}
                centered={true}
                options={[
                    {
                        id: 'store-page',
                        label: (
                            <CustomButton
                                {...customButtonProps}
                                children="Nhà thuốc"
                                onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                    setMenuTabItem([
                                        '/',
                                        '/thuoc',
                                        '/thuc-pham-chuc-nang',
                                        '/san-pham-khac',
                                        '/bai-viet-suc-khoe',
                                    ]);
                                }}
                                startIcon={<StoreIcon />}
                            />
                        ),
                        onClick: () => {},
                        sx,
                    },
                    {
                        id: 'page-management',
                        label: (
                            <CustomButton
                                {...customButtonProps}
                                children="Quản lý Page"
                                onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                    setMenuTabItem([
                                        '/admin/homepage',
                                        '/admin/product-item',
                                        '/admin/manufacture',
                                        '/admin/ingredient',
                                        '/admin/article-item',
                                        '/admin/keyword',
                                        '/admin/market',
                                    ]);
                                }}
                                startIcon={<InventoryIcon fontSize="small" />}
                            />
                        ),
                        onClick: () => {},
                        sx,
                    },
                    {
                        id: 'list-page',
                        label: (
                            <CustomButton
                                {...customButtonProps}
                                children="Danh sách"
                                onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                    setMenuTabItem([
                                        '/admin/staff',
                                        '/admin/commission-products',
                                        '/admin/salary',
                                    ]);
                                }}
                                startIcon={<StorageIcon fontSize="small" />}
                            />
                        ),
                        onClick: () => {},
                        sx,
                    },
                ]}
            />
            {Boolean(anchorEl) && (
                <CustomMenu
                    id="menu-admin-navbar"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    options={Object.entries(breadcrumbNameMapping)
                        .filter(([key]) => menuTabItem.includes(key))
                        .map(([pathname, label]) => {
                            return {
                                value: label,
                                selected: location === pathname,
                                onClick: () => {
                                    setAnchorEl(null);
                                    setMenuTabItem([]);
                                    navigate(pathname);
                                },
                                children: label,
                            };
                        })}
                />
            )}
        </Box>
    );
}
