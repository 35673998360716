import { Stack, Typography, Link } from '@mui/material';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';

import { Link as RouterLink, useNavigate } from 'react-router-dom';

import cateImageJson from 'data/cateImage.json';

import { CustomAvatar } from 'UI';

const customTheme = (outerTheme) =>
    createTheme({
        components: {
            MuiTypography: {
                styleOverrides: {
                    noWrap(styles) {
                        return {
                            whiteSpace: 'initial',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: String(
                                styles.ownerState['data-lines'] || '3'
                            ),
                            WebkitBoxOrient: 'vertical',
                        };
                    },
                },
            },
        },
    });

const StyledTypography = ({ children, ...otherProps }) => {
    const theme = useTheme();
    return (
        <ThemeProvider theme={customTheme(theme)}>
            <Typography
                variant="caption"
                color="black"
                noWrap
                data-lines={'1'}
                component="h2"
                fontSize="14px"
                fontWeight={500}
                sx={{
                    pr: 1,
                    maxWidth: '95%',
                    textTransform: 'initial',
                    textAlign: { xs: 'left', sm: 'center' },
                    height: 18,
                    lineHeight: 1.2,
                }}>
                {children}
            </Typography>
        </ThemeProvider>
    );
};

export default function CategoryCardItem({
    id = Math.random() * 1000,
    name = '',
    slug = '/',
    ...otherProps
}) {
    const navigate = useNavigate();
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            onClick={() => {
                navigate(`/bai-viet-suc-khoe${slug}`);
            }}
            sx={{
                cursor: 'pointer',
                minHeight: 40,
                // borderRadius: '10px',
                backgroundColor: '#EDEDED',
            }}>
            <CustomAvatar
                src={cateImageJson?.[slug]}
                variant="rounded"
                styleProps={{ p: 1 }}
                alt=""
                size={45}
                id={id + '-custom-avatar'}
            />
            <StyledTypography>{name}</StyledTypography>
        </Stack>
    );
}
