import { useState, useEffect, useRef, useMemo } from 'react';

import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';

import { CustomButton } from 'UI';
import { hideScrollbar } from 'config/customCSS.js';

const Masonry = ({
    children,
    columns = { xs: 1, sm: 2, md: 3, lg: 4, xl: 6 },
    spacing = 2,
    wrap = true,
}) => {
    const theme = useTheme();
    const matches = {
        xs: useMediaQuery(theme.breakpoints.up('xs')),
        sm: useMediaQuery(theme.breakpoints.up('sm')),
        md: useMediaQuery(theme.breakpoints.up('md')),
        lg: useMediaQuery(theme.breakpoints.up('lg')),
        xl: useMediaQuery(theme.breakpoints.up('xl')),
    };
    let modifiedColumns = {};
    if (columns.hasOwnProperty('xs')) {
        modifiedColumns.xs = columns.xs;
    } else {
        modifiedColumns.xs = 1;
    }
    if (columns.hasOwnProperty('sm')) {
        modifiedColumns.sm = columns.sm;
    } else {
        modifiedColumns.sm = modifiedColumns.xs;
    }
    if (columns.hasOwnProperty('md')) {
        modifiedColumns.md = columns.md;
    } else {
        modifiedColumns.md = modifiedColumns.sm;
    }
    if (columns.hasOwnProperty('lg')) {
        modifiedColumns.lg = columns.lg;
    } else {
        modifiedColumns.lg = modifiedColumns.md;
    }
    if (columns.hasOwnProperty('xl')) {
        modifiedColumns.xl = columns.xl;
    } else {
        modifiedColumns.xl = modifiedColumns.lg;
    }

    const getColumns = () => {
        if (matches.xl) return modifiedColumns.xl;
        if (matches.lg) return modifiedColumns.lg;
        if (matches.md) return modifiedColumns.md;
        if (matches.sm) return modifiedColumns.sm;
        return modifiedColumns.xs;
    };

    const columnsCount = getColumns();

    const createColumns = () => {
        const columnsArray = Array.from({ length: columnsCount }, () => []);
        children.forEach((child, index) => {
            columnsArray[index % columnsCount].push(
                <Box key={index} mb={spacing}>
                    {child}
                </Box>
            );
        });
        return columnsArray;
    };

    const columnsArray = createColumns();
    const itemWidth = useMemo(
        () =>
            `calc((100% - ${
                spacing * 8 * (columnsCount - 1)
            }px) / ${columnsCount})`,
        [columnsCount, spacing]
    );

    const scrollContainerRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollContainerRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } =
                    scrollContainerRef.current;
                setShowLeftArrow(scrollLeft > 0);
                setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
            }
        };

        handleScroll(); // Initial check
        scrollContainerRef.current?.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        return () => {
            scrollContainerRef.current?.removeEventListener(
                'scroll',
                handleScroll
            );
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    const scroll = (direction) => {
        const { current } = scrollContainerRef;
        if (current) {
            const scrollAmount = direction === 'left' ? -300 : 300;
            current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    if (!wrap) {
        return (
            <Box position="relative">
                {showLeftArrow && (
                    <CustomButton
                        id={`arrow-left-custom-button-${Math.random() * 1000}`}
                        useIconButton
                        size="small"
                        variant="base"
                        onClick={() => scroll('left')}
                        styleProps={{
                            position: 'absolute',
                            left: -20,
                            border: '1px solid white',
                            boxShadow: 1,
                            top: '40%',
                            zIndex: 1,
                            backgroundColor: 'white',
                            ':hover': {
                                backgroundColor: 'white',
                                border: '1px solid darkblue',
                            },
                            p: 0.25,
                        }}>
                        <KeyboardArrowLeftIcon fontSize="large" />
                    </CustomButton>
                )}
                {showRightArrow && (
                    <CustomButton
                        id={`arrow-right-custom-button-${Math.random() * 1000}`}
                        useIconButton
                        size="small"
                        variant="base"
                        onClick={() => scroll('right')}
                        styleProps={{
                            position: 'absolute',
                            right: -20,
                            border: '1px solid white',
                            boxShadow: 1,
                            top: '40%',
                            zIndex: 1,
                            backgroundColor: 'white',
                            ':hover': {
                                backgroundColor: 'white',
                                border: '1px solid darkblue',
                            },
                            p: 0.25,
                        }}>
                        <KeyboardArrowRightIcon
                            fontSize="large"
                            sx={{ color: 'darkblue' }}
                        />
                    </CustomButton>
                )}
                <Box
                    display="flex"
                    overflow="auto"
                    ref={scrollContainerRef}
                    sx={hideScrollbar}>
                    {children.map((child, index) => (
                        <Box key={index} mr={spacing} minWidth={itemWidth}>
                            {child}
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    }

    return (
        <Grid container spacing={spacing}>
            {columnsArray.map((column, index) => (
                <Grid item xs={12 / columnsCount} key={index}>
                    {column.map((item) => item)}
                </Grid>
            ))}
        </Grid>
    );
};

export default function CustomMasonry({
    id, // required
    items = [],
    /*
        required
        [{  
            id          // required
            content     // required, accpet a node
        }]
    */
    elevation = 2, // optional, elevation for Paper of each item
    columns = { xs: 1, sm: 2, md: 3, lg: 4, xl: 6 },
    spacing = 2, // optional, padding = 4*spacing for Paper of each item
    minHeight = 200, // optional, minHeight for Paper of each item
    wrap = true,
    ...otherProps
}) {
    return (
        <Box sx={{ padding: Number(spacing * 8) + 'px' }}>
            <Masonry id={id} spacing={spacing} columns={columns} wrap={wrap}>
                {items.map((item) => {
                    const { id = Math.random() * 1000, content = null } = item;
                    return (
                        <Paper
                            elevation={elevation}
                            key={id + '-item-masonry'}
                            sx={{
                                '&.MuiPaper-root': {
                                    backgroundColor: 'transparent',
                                    borderRadius: '0',
                                    boxShadow: 'none',
                                    minHeight,
                                },
                            }}>
                            {content}
                        </Paper>
                    );
                })}
            </Masonry>
        </Box>
    );
}
