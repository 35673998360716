import { Typography } from '@mui/material';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { logout } from 'utils/auth.js';
import { userInfoActions } from 'store/userInfo-slice.js';

import { CustomDialog } from 'UI';

export default function LogoutDialog({
    open = false,
    onClose = () => {},
    ...props
}) {
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const navigate = useNavigate();

    return (
        <CustomDialog
            id="logout-custom-dialog"
            severity="warning"
            title="Đăng xuất?"
            open={open}
            maxWidth="xs"
            onClose={onClose}
            hideNoButton={false}
            labelNoButon="Hủy"
            onNo={onClose}
            onYes={() => {
                logout();
                dispatch(userInfoActions.deleteUserInfo());
                onClose();
                if (location.includes('/admin')) {
                    navigate('/');
                }
            }}>
            <Typography>
                Bạn đang thực hiện đăng xuất khỏi tài khoản.
            </Typography>
            <Typography>Bạn có chắc chắn muốn tiếp tục?</Typography>
        </CustomDialog>
    );
}
