import { useState, forwardRef } from 'react';

import {
    Typography,
    Stack,
    useMediaQuery,
    Box,
    Popper,
    Paper,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    Search as SearchIcon,
    History as HistoryIcon,
    Close as CloseIcon,
    ArrowBackIosNew as ArrowBackIosNewIcon,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { searchHistoryActions } from 'store/searchHistory-slice.js';
import { systemActions } from 'store/system-slice.js';

import { CustomButton, CustomSearch, CustomChip, CustomList } from 'UI';

function CustomPopper(props) {
    return <Popper {...props} placement="bottom-end" />;
}
function CustomPaper({ children, ...props }) {
    return (
        <Paper
            {...props}
            sx={{
                '& .MuiAutocomplete-noOptions': {
                    px: 0,
                },
                position: 'absolute',
                right: { xs: -25, sm: -5 },
                marginTop: '18px',
                borderRadius: { xs: 0, sm: '15px' },
                border: { xs: 'none', sm: '1px solid rgba(0, 0, 139, 0.75)' },
                height: { xs: 'calc(100vh - 70px)', sm: 'auto' },
                width: {
                    xs: '100vw',
                    sm: 'calc(100% + 20px)',
                },
            }}>
            {children}
        </Paper>
    );
}
const CustomListbox = forwardRef(function CustomListbox(
    { children, ...props },
    ref
) {
    return (
        <Box
            component={'ul'}
            ref={ref}
            {...props}
            sx={{
                maxHeight: { xs: '100vh', sm: '60vh' },
                padding: '0px !important',
            }}>
            {children}
        </Box>
    );
});

export default function SearchAppbar({
    open = false,
    onOpen = () => {},
    ...props
}) {
    const dispatch = useDispatch();
    const searchHistory = useSelector((state) => state.searchHistory);

    const navigate = useNavigate();
    const theme = useTheme();
    const biggerSM = useMediaQuery(theme.breakpoints.up('sm')); // >= 600
    const biggerMD = useMediaQuery(theme.breakpoints.up('md')); // >= 800

    const [searchValue, setSearchValue] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const handleDeleteItemSearchHistory = (searchTerm) => {
        dispatch(searchHistoryActions.deleteItemSearchHistory(searchTerm));
    };
    const handleClearSearchHistory = () => {
        dispatch(searchHistoryActions.clearSearchHistory());
    };
    const handleAddSearchHistory = (searchTerm) => {
        dispatch(searchHistoryActions.addSearchHistory(searchTerm));
    };
    const handleOpen = () => {
        onOpen(true);
        dispatch(systemActions.toggleDimBackground(true));
        document.body.style.overflow = 'hidden';
    };
    const handleClose = (event, reason) => {
        if (!biggerSM && reason !== 'selectOption') {
            return;
        }
        onOpen(false);
        dispatch(systemActions.toggleDimBackground(false));
        document.body.style.overflow = '';
    };

    const handleSearch = (searchTerm) => {
        if (searchTerm) {
            handleAddSearchHistory(searchTerm);
            navigate('/tim-kiem?s=' + searchTerm);
            setSearchValue(null);
        }
    };
    const handleChange = (newValue, reason) => {
        if (newValue) {
            // save to localStorage when clicking keyword
            if (newValue.type === 'keyword' && newValue.value) {
                handleSearch(newValue.value);
                // go to page /tim-kiem and render with searchTerm = newValue.value
            } else if (
                newValue.type === 'product-item' &&
                reason === 'selectOption'
            ) {
                navigate(`/san-pham${newValue.slug}`);
                handleClose(null, 'selectOption');
                // go to exact Page product item by slug from obj newValue
            }
        }
    };
    let suggestion = (
        <Stack alignItems="flex-start" spacing={1.5}>
            {searchHistory.length > 0 && (
                <Stack alignItems="flex-start" width="100%">
                    <Stack
                        px={2.5}
                        width="100%"
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between">
                        <Typography fontWeight={600}>
                            Lịch sử tìm kiếm
                        </Typography>
                        <CustomButton
                            id="clear-all-search-history-custom-button"
                            variant="base"
                            size="small"
                            color="blue"
                            onClick={handleClearSearchHistory}
                            children="Xóa tất cả"
                        />
                    </Stack>
                    <CustomList
                        id="searchHistory-custom-list"
                        alignItems="center"
                        ListProps={{ sx: { width: '100%', p: 0 } }}
                        useListItemButton={false}
                        options={searchHistory.slice(0, 5).map((term) => ({
                            icon: (
                                <HistoryIcon
                                    fontSize="small"
                                    onClick={() => {
                                        handleSearch(term);
                                        handleClose(null, 'selectOption');
                                    }}
                                    sx={{
                                        color: 'blue',
                                        opacity: 0.8,
                                    }}
                                />
                            ),
                            ListItemProps: {
                                sx: {
                                    py: 0.75,
                                    cursor: 'pointer',
                                    ':hover': {
                                        backgroundColor: '#EDEDED',
                                    },
                                },
                            },
                            children: (
                                <Stack
                                    width="100%"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between">
                                    <Typography
                                        onClick={() => {
                                            handleSearch(term);
                                            handleClose(null, 'selectOption');
                                        }}
                                        color="blue"
                                        sx={{ opacity: 0.8, width: '100%' }}>
                                        {term}
                                    </Typography>
                                    <CustomButton
                                        variant="base"
                                        id={Math.random() * 1000}
                                        onClick={() =>
                                            handleDeleteItemSearchHistory(term)
                                        }
                                        styleProps={{
                                            p: 0.25,
                                            ':hover': {
                                                backgroundColor:
                                                    'rgba(0,0,0,0.05)',
                                            },
                                        }}
                                        useIconButton>
                                        <CloseIcon fontSize="small" />
                                    </CustomButton>
                                </Stack>
                            ),
                        }))}
                    />
                </Stack>
            )}
            <Stack alignItems="flex-start" spacing={1.5} px={1}>
                <Typography fontWeight={600} px={1.5}>
                    Tra cứu hàng đầu
                </Typography>
                <Stack
                    direction="row"
                    flexWrap="wrap"
                    alignItems="center"
                    spacing={1}>
                    {[
                        'dung dịch vệ sinh',
                        'men vi sinh',
                        'sữa rửa mặt',
                        'siro tăng đề kháng',
                        'kem chống nắng',
                        'D3k2',
                        'canxi',
                    ].map((chip, idx) => {
                        return (
                            <CustomChip
                                key={Math.random() * 1000}
                                label={chip}
                                styleProps={{
                                    '&.MuiChip-root': {
                                        border: `1px solid lightgrey`,
                                        backgroundColor: 'transparent',
                                        color: 'black',
                                        ':hover': {
                                            backgroundColor: '#EDEDED',
                                        },
                                        my: 0.5,
                                        ml: idx === 0 ? 1.25 : 1,
                                    },
                                }}
                                variant="outlined"
                                onClick={() => {
                                    handleSearch(chip);
                                    handleClose(null, 'selectOption');
                                }}
                            />
                        );
                    })}
                </Stack>
            </Stack>
        </Stack>
    );
    let convertArrOptions = (options) => {
        return options.map(({ value, type }) => {
            if (type === 'product-item') {
                return {
                    type,
                    label: value.shortName,
                    value: value._id,
                    avatar:
                        value.primaryImage.mainSrc ||
                        '../assets/images/khanhtranglogo-02.png',
                    sublabel: (
                        <Stack mt={1} alignItems="flex-start" spacing={0.25}>
                            <Typography fontSize={14}>
                                {value.webName}
                            </Typography>
                            {value.prescription && (
                                <Typography
                                    variant="subttile2"
                                    fontSize={14}
                                    color="grey">
                                    Cần tư vấn của dược sỹ
                                </Typography>
                            )}
                            <Typography fontSize={12}>
                                {value.category[value.category.length - 1].name}
                            </Typography>
                        </Stack>
                    ),
                    ...value,
                };
            } else if (type === 'keyword') {
                return {
                    type,
                    label: value,
                    value,
                    avatar: null,
                    icon: (
                        <SearchIcon
                            fontSize="small"
                            sx={{ color: 'blue', opacity: 0.75 }}
                        />
                    ),
                    sublabel: null,
                };
            } else if (type === 'see-more') {
                return {
                    type,
                    label: null,
                    value,
                    avatar: null,
                    icon: null,
                    sublabel: (
                        <CustomButton
                            variant="text"
                            size="small"
                            fullWidth
                            styleProps={{
                                mb: -0.5,
                                fontSize: 14,
                                fontWeight: 500,
                                textAlign: 'center',
                                ':hover': {
                                    backgroundColor: '#EDEDED',
                                },
                            }}
                            endIcon={
                                <ArrowBackIosNewIcon
                                    sx={{
                                        transform: 'rotate(180deg)',
                                        width: 14,
                                        height: 14,
                                    }}
                                />
                            }
                            onClick={() =>
                                handleChange({
                                    type: 'keyword',
                                    value: searchTerm,
                                })
                            }
                            children="Xem tất cả"
                        />
                    ),
                };
            } else return null;
        });
    };
    return (
        <CustomSearch
            id="page-appbar-custom-search" // important to render width of paper
            name="appbar"
            pathname="/search/suggest"
            {...configProps}
            value={searchValue}
            getInputValue={(inputValue) => {
                setSearchTerm(inputValue);
            }}
            onChange={handleChange}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    handleSearch(searchTerm);
                    handleClose(null, 'selectOption');
                }
            }}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            convertArrOptions={convertArrOptions}
            initialText={suggestion}
            margin={biggerMD ? 'normal' : 'none'}
            size={biggerMD ? 'medium' : 'small'}
            styleProps={{
                border: '1px solid rgba(0, 0, 139, 0.75)',
                borderRadius: '30px !important',
                padding: biggerMD ? '10px 8px 10px 14px' : '4px 8px 4px 14px',
                backgroundColor: 'white',
                '& .MuiInputBase-root': {
                    mt: 0,
                },
                '& .MuiInput-root': {
                    '::before': { content: 'none' },
                    '::after': { borderBottom: 'none' },
                },
                '& .MuiAutocomplete-popupIndicator': {
                    display: 'none',
                },
            }}
            endAdornment={
                <CustomButton
                    onClick={() => {
                        if (searchTerm) {
                            handleSearch(searchTerm);
                            handleClose(null, 'selectOption');
                        } else {
                            if (open) {
                                handleClose(null, 'selectOption');
                            } else {
                                handleOpen();
                            }
                        }
                    }}
                    useIconButton
                    styleProps={{ p: 0, mr: -4 }}
                    variant="base">
                    <SearchIcon
                        sx={{
                            width: 32,
                            height: 32,
                            backgroundColor: 'rgba(0, 0, 139, 0.75)',
                            borderRadius: '50%',
                            p: 0.75,
                            color: 'white',
                        }}
                    />
                </CustomButton>
            }
            PopperComponent={CustomPopper}
            PaperComponent={CustomPaper}
            ListboxComponent={CustomListbox}
        />
    );
}

let configProps = {
    groupBy: (option) => option.type,
    renderGroup: (params) => {
        return (
            <li key={params.key}>
                {params.key !== 0 && (
                    <Box
                        sx={{
                            mx: 'auto',
                            width: '98%',
                            height: '1px',
                            backgroundColor: 'lightgrey',
                        }}></Box>
                )}
                <ul style={{ padding: 0 }}>{params.children}</ul>
            </li>
        );
    },
    getOptionKey: (option) => Math.random() * 1000,
    error: '',
    placeholder: 'Tìm tên thuốc, thực phẩm chức năng...',
    disableCloseOnSelect: false,
    clearOnEscape: true,
    clearOnBlur: false,
    disableClearable: true,
    openOnFocus: false,
    InputLabelProps: { shrink: true },
    fullWidth: true,
    multiple: false,
    multiline: false,
    autoHighlight: false,
    variant: 'standard',
    filterOptions: (x) => x,
};
