import { forwardRef } from 'react';

import { Box, Collapse, Typography } from '@mui/material';
import {
    FolderRounded as FolderRoundedIcon,
    FolderOpenRounded as FolderOpenRoundedIcon,
} from '@mui/icons-material';

import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2';
import {
    TreeItem2Content,
    TreeItem2IconContainer,
    TreeItem2Label,
    TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';

const DotIcon = () => (
    <Box
        sx={{
            width: 6,
            height: 6,
            borderRadius: '70%',
            bgcolor: 'orange',
            display: 'inline-block',
            verticalAlign: 'middle',
            zIndex: 1,
            mx: 1,
        }}
    />
);

function CustomLabel({
    icon: Icon,
    openIcon: OpenIcon,
    expandable,
    children,
    ...other
}) {
    return (
        <TreeItem2Label
            {...other}
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}>
            {Icon && (
                <Box
                    component={expandable ? OpenIcon : Icon}
                    sx={{
                        mr: 1,
                        fontSize: '1.2rem',
                        color: expandable ? 'orange' : 'inherit',
                    }}
                />
            )}
            <Typography
                variant="body2"
                sx={{
                    color: 'inherit',
                    fontWeight: 500,
                }}>
                {children}
            </Typography>
            {expandable && <DotIcon />}
        </TreeItem2Label>
    );
}

const isExpandable = (reactChildren) => {
    if (Array.isArray(reactChildren)) {
        return reactChildren.length > 0 && reactChildren.some(isExpandable);
    }
    return Boolean(reactChildren);
};

const CustomTreeItem = forwardRef(function CustomTreeItem(props, ref) {
    const { id, itemId, label, disabled, children, ...other } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    const expandable = isExpandable(children);

    return (
        <TreeItem2Provider itemId={itemId}>
            <TreeItem2Root
                {...getRootProps({
                    ...other,
                    sx: {
                        color: 'rgba(0,0,0,0.7)',
                        position: 'relative',
                        [`& .${treeItemClasses.groupTransition}`]: {
                            marginLeft: 3.5,
                        },
                    },
                })}>
                <TreeItem2Content
                    {...getContentProps({
                        sx: {
                            flexDirection: 'row-reverse',
                            borderRadius: 0.7,
                            mb: 0.5,
                            mt: 0.5,
                            p: 0.5,
                            pr: 1,
                            fontWeight: 500,
                            '&.Mui-expanded': {
                                '&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon':
                                    {
                                        color: 'blue',
                                    },
                                '&::before': {
                                    content: '""',
                                    position: 'absolute',
                                    left: '16px',
                                    top: '44px',
                                    height: 'calc(100% - 48px)',
                                    width: '1.5px',
                                    backgroundColor: '#EDEDED',
                                },
                            },
                            '&:hover': {
                                color: 'white !important',
                                backgroundColor: 'rgba(0,0,139,0.7)',
                            },
                            '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused':
                                {
                                    backgroundColor: 'orange',
                                    color: 'darkblue',
                                },
                        },
                    })}>
                    <TreeItem2IconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon
                            status={status}
                            sx={{ fontSize: '1.5rem', color: 'inherit' }}
                        />
                    </TreeItem2IconContainer>
                    <CustomLabel
                        {...getLabelProps({
                            icon: FolderRoundedIcon,
                            openIcon: FolderOpenRoundedIcon,
                            expandable: expandable && status.expanded,
                        })}
                    />
                </TreeItem2Content>
                {children && (
                    <Collapse
                        in={status.expanded}
                        timeout="auto"
                        sx={{ pl: 4 }}
                        {...getGroupTransitionProps()}>
                        {children}
                    </Collapse>
                )}
            </TreeItem2Root>
        </TreeItem2Provider>
    );
});

export default function CustomRichTreeView({ items = [], ...otherProps }) {
    return (
        <RichTreeView
            items={items}
            sx={{
                height: 'fit-content',
                flexGrow: 1,
                maxWidth: 400,
                overflowY: 'auto',
            }}
            slots={{ item: CustomTreeItem }}
            disableSelection
        />
    );
}

// sample items format
const ITEMS = [
    {
        id: '1',
        label: 'Documents',
        children: [
            {
                id: '1.1',
                label: 'Company',
                children: [
                    { id: '1.1.1', label: 'Invoice' },
                    { id: '1.1.2', label: 'Meeting notes' },
                    { id: '1.1.3', label: 'Tasks list' },
                    { id: '1.1.4', label: 'Equipment' },
                    { id: '1.1.5', label: 'Video conference' },
                ],
            },
            { id: '1.2', label: 'Personal' },
            { id: '1.3', label: 'Group photo' },
        ],
    },
    {
        id: '2',
        label: 'Bookmarked',
        children: [
            { id: '2.1', label: 'Learning materials' },
            { id: '2.2', label: 'News' },
            { id: '2.3', label: 'Forums' },
            { id: '2.4', label: 'Travel documents' },
        ],
    },
    { id: '3', label: 'History' },
    { id: '4', label: 'Trash' },
];
