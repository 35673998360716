import { useState, useEffect } from 'react';

import {
    Stack,
    Typography,
    TextField,
    Autocomplete,
    Chip,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { CustomButton, CustomAvatar, CustomHighlightText } from 'UI';

import useDebounce from 'hooks/useDebounce.js';
import { getColorRGBA } from 'utils/getColorRGBA.js';

function getStyles({
    size = 'medium',
    color = 'disabled',
    variant = 'outlined',
    multiline = false,
}) {
    let chosenColor = getColorRGBA(color);
    let css = {
        standard: {
            '& label': {
                borderRadius: '5px',
                px: 1,
                ml: -0.85,
                mt: size === 'small' ? -0.5 : 0.25,
                '&.MuiInputLabel-shrink': {
                    mt: 0,
                },

                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0,0,0,0.26)',
                },
            },
            '& p': {
                '&.MuiFormHelperText-root': {
                    ml: 0,
                },
            },
            '& .MuiInputBase-root': {
                height: multiline ? 'auto' : size === 'small' ? 33 : 32,
                borderRadius: '5px',
                color: chosenColor,
                ...(size === 'small' && { mt: multiline ? '13px' : 1.5 }),
                ...(multiline === false && { overflow: 'hidden' }),
                ':hover:not(.Mui-disabled, .Mui-error):before': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                },
                '::after': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                    '&:hover': {
                        borderBottom: `1px solid ${chosenColor}`,
                    },
                },
                '&.Mui-disabled:before': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
            },
        },
        filled: {
            '& label': {
                borderRadius: '5px',
                px: 1,
                ml: -0.75,
                mt: size === 'small' ? -0.25 : 0,
                '&.MuiInputLabel-shrink': {
                    mt: -0.5,
                },
                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0, 0, 0, 0.26)',
                },
            },
            '& .MuiFilledInput-root': {
                height: multiline ? 'auto' : size === 'small' ? 48 : 56,
                ...(size === 'small' && {
                    paddingTop: multiline ? '17px' : '19px',
                    '& input': { mt: -0.75 },
                }),
                ...(multiline === false && { overflow: 'hidden' }),
                backgroundColor: 'aliceblue',
                borderRadius: '5px 5px 0 0',
                color: chosenColor,
                ':hover:not(.Mui-disabled, .Mui-focused)': {
                    backgroundColor: 'rgba(0,40,139,0.1)',
                },
                '&.Mui-focused': {
                    backgroundColor: 'aliceblue',
                },
                ':hover:not(.Mui-disabled, .Mui-error):before': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                },
                '::after': {
                    border: 'none',
                    borderBottom: `1px solid ${chosenColor}`,
                    '&:hover': {
                        borderBottom: `1px solid ${chosenColor}`,
                    },
                },
                '&.Mui-error:focus': {
                    borderBottom: '1px solid rgb(176, 0, 32)',
                },
                '&.Mui-disabled': {
                    backgroundColor: 'lightgrey',
                },
                '&.Mui-disabled:before': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
                '&.Mui-disabled:after': {
                    borderBottom: '1px solid rgb(0,0,0,0.26)',
                },
            },
        },
        outlined: {
            '& label': {
                borderRadius: '5px',
                px: 1,
                ml: -0.75,
                mt: size === 'small' ? -0.75 : 0,
                '&.MuiInputLabel-shrink': {
                    mt: 0,
                },
                '&.Mui-focused': {
                    color: chosenColor,
                },
                ':hover': {
                    color: chosenColor,
                },
                '&.Mui-error': {
                    color: 'rgb(211, 47, 47)',
                },
                '&.Mui-disabled': {
                    color: 'rgb(0,0,0,0.26)',
                },
            },
            '& .MuiOutlinedInput-root': {
                height: multiline ? 'auto' : size === 'small' ? 41 : 56,
                ...(size === 'small' && {
                    padding: multiline ? '6px 8px' : '9px',
                    '& input': { mt: -1 },
                }),
                ...(multiline === false && { overflow: 'hidden' }),
                borderRadius: '5px',
                color: chosenColor,
                '& fieldset': {
                    borderRadius: '5px',
                    borderWidth: '1px',
                },
                '&:hover:not(.Mui-disabled, .Mui-error) fieldset': {
                    border: `1px solid ${chosenColor}`,
                },
                '&.Mui-focused fieldset': {
                    border: `1px solid ${chosenColor}`,
                },
                '&.Mui-error fieldset': {
                    border: '1px solid rgb(211, 47, 47)',
                },
                '&.Mui-disabled fieldset': {
                    border: '1px solid rgb(0,0,0,0.26)',
                },
            },
        },
    };

    return css[variant];
}
function TemplateOption({
    label,
    sublabel = null,
    compareString,
    avatar = null,
    useHighlight = true,
    selected = false,
    ...otherProps
}) {
    return (
        <Stack
            direction="row"
            alignItems="flex-start"
            spacing={1}
            width="100%"
            {...(avatar && {
                sx: {
                    my: 0.5,
                    borderLeft: '3px solid darkblue',
                },
            })}>
            {avatar &&
                (typeof avatar === 'string' ? (
                    <CustomAvatar
                        size={80}
                        variant="rounded"
                        alt="avatar-data-thumbnail"
                        src={avatar}
                    />
                ) : (
                    avatar
                ))}
            <Stack justifyContent="flex-start" width="100%">
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%">
                    <CustomHighlightText
                        label={label}
                        keyword={compareString}
                    />
                    {selected && (
                        <span key={'parts-selected-value' + label}>{`✔ `}</span>
                    )}
                </Stack>
                {sublabel &&
                    (typeof sublabel === 'string' ? (
                        <Typography
                            variant="caption"
                            fontStyle="italic"
                            whiteSpace="normal">
                            {sublabel}
                        </Typography>
                    ) : (
                        sublabel
                    ))}
            </Stack>
        </Stack>
    );
}
export default function CustomComboBox({
    id, // required
    options = [], // required, input structure: ['value-1','value-2',...]
    convertArrOptions = (value) => value,
    /*
        required
        input: options
        output structure: 
            [{
                value: 'test',                   required, must be unique value
                label: 'Content',                required
                sublabel,                        optional, use for TemplateOption, accept: string, node
                avatar                           optional, use for TemplateOption, accept: string, node
            }]
    */
    value, // required if controlled(initialState = null if !multiple and = [] if multiple)
    onChange = () => {}, // required if controlled
    multiple = true, // required
    multiline = true, // required
    disabled = false,
    readOnly = false,
    required = false,
    fullWidth = true,
    disableCloseOnSelect = true,
    clearOnEscape = true,
    label = '',
    error = '',
    helperText = '',
    placeholder = '',
    size = 'medium', // accepted: medium, small
    color = 'default', // accepted: default, success, error, info, warning
    margin = 'normal', // accepted: normal, dense, none
    limitTags = 10,
    variant = 'outlined', // accepted: filled, outlined, standard
    inputProps,
    InputLabelProps,
    inputRef,
    useHighlight = true, // use for highlight characters match inputValue
    allowAddNewValue = false,
    onAddNewValue = () => {},
    ...otherProps
}) {
    const [inputValue, setInputValue] = useState('');
    const debouncedInputValue = useDebounce(inputValue, 300);
    const styles = getStyles({
        size,
        color,
        variant,
        multiline: multiple ? true : multiline,
    });

    useEffect(() => {
        setInputValue(value?.label || '');
    }, [value?.label]);

    return (
        <Autocomplete
            id={id}
            disablePortal
            clearOnBlur
            autoHighlight
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
                option.label === value.label
            }
            disabled={disabled}
            readOnly={readOnly}
            required={required}
            multiple={multiple}
            fullWidth={fullWidth}
            limitTags={limitTags}
            disableCloseOnSelect={disableCloseOnSelect}
            clearOnEscape={clearOnEscape}
            options={convertArrOptions(options)}
            value={value}
            onChange={(event, newValue) => {
                onChange(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            noOptionsText={
                debouncedInputValue.trim() ? (
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        width="100%"
                        justifyContent={'space-between'}>
                        <Typography>Không có kết quả phù hợp.</Typography>
                        {allowAddNewValue && (
                            <CustomButton
                                variant={variant}
                                size="small"
                                margin="none"
                                id="add-new-manufactureCompany-custom-button"
                                children="Thêm"
                                onClick={onAddNewValue}
                                startIcon={<AddIcon />}
                            />
                        )}
                    </Stack>
                ) : (
                    <Typography>{placeholder}</Typography>
                )
            }
            clearText="Xóa"
            renderInput={(params) => {
                const {
                    InputProps = undefined,
                    inputProps: defaultInputProps = undefined,
                    ...otherParams
                } = params;

                return (
                    <TextField
                        label={label}
                        variant={variant}
                        autoComplete="new-password"
                        autoFocus={false}
                        aria-describedby={`${id}-helper-text`}
                        inputRef={inputRef}
                        placeholder={placeholder}
                        InputLabelProps={InputLabelProps}
                        disabled={disabled}
                        required={required}
                        margin={margin}
                        error={Boolean(error)}
                        helperText={error ? error : helperText}
                        InputProps={InputProps}
                        inputProps={{
                            ...defaultInputProps,
                            ...inputProps,
                        }}
                        sx={styles}
                        {...otherParams}
                    />
                );
            }}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                    const { label = `Option ${index + 1}` } = option;
                    return (
                        <Chip
                            size={size}
                            label={label}
                            {...getTagProps({ index })}
                        />
                    );
                })
            }
            renderOption={(props, option, { selected }) => {
                const {
                    value: optValue,
                    label: optLabel,
                    sublabel = null,
                    avatar = null,
                } = option;

                return (
                    <li {...props} key={optLabel + '-' + optValue}>
                        <TemplateOption
                            label={optLabel}
                            sublabel={sublabel}
                            compareString={debouncedInputValue}
                            avatar={avatar}
                            useHighlight={useHighlight}
                            selected={selected}
                        />
                    </li>
                );
            }}
            {...otherProps}
        />
    );
}
