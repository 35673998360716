import { Fragment, useState } from 'react';

import {
    Stack,
    Grid,
    Typography,
    useMediaQuery,
    ListItemText,
    Box,
    List,
    ListItemButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    Facebook as FacebookIcon,
    YouTube as YouTubeIcon,
    PinDrop as PinDropIcon,
    Schedule as ScheduleIcon,
    WhatsApp as WhatsAppIcon,
    Share as ShareIcon,
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';

import breadcrumbNameMapping from 'data/breadcrumbNameMapping.json';

import { CustomButton, CustomList, CustomCollapseList } from 'UI';

import LogoBrand from '../LogoBrand.jsx';

const listMainDrawerRouter = [
    '/thuoc',
    '/thuc-pham-chuc-nang',
    '/san-pham-khac',
    '/bai-viet-suc-khoe',
];
const listCustomerServiceRouter = [
    '/khach-hang-than-thiet',
    '/quy-che-hoat-dong',
    '/chinh-sach-giao-hang',
    '/chinh-sach-thanh-toan',
    '/chinh-sach-doi-tra-hang',
    '/chinh-sach-bao-mat',
    '/chinh-sach-thu-thap-va-xu-ly-du-lieu',
];
export default function SectionFooter({ ...otherProps }) {
    const theme = useTheme();
    const biggerMD = useMediaQuery(theme.breakpoints.up('md')); // >= 600
    const navigate = useNavigate();

    const [isOpenCollases, setIsOpenCollapses] = useState({
        contact: false,
        cateLv1: false,
        about: false,
    });

    if (!biggerMD) {
        return (
            <List sx={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                {[
                    {
                        isOpenCollapse: isOpenCollases.contact,
                        handleClickCollapse: () => {
                            setIsOpenCollapses((prevValue) => {
                                let updatedValue = { ...prevValue };
                                updatedValue.contact = !updatedValue.contact;
                                return updatedValue;
                            });
                        },
                        subHeaderText: 'THÔNG TIN LIÊN HỆ',
                        listOptions: [
                            {
                                id: 'phone-contact',
                                icon: (
                                    <WhatsAppIcon
                                        fontSize="small"
                                        sx={{ mt: -0.2, color: 'darkblue' }}
                                    />
                                ),
                                children: (
                                    <ListItemText
                                        primary={
                                            <div>
                                                <Typography
                                                    component="a"
                                                    href="tel:0704430919"
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    sx={{
                                                        '&:hover': {
                                                            color: 'blue',
                                                            backgroundColor:
                                                                'transparent',
                                                        },
                                                        textDecoration: 'none',
                                                        color: 'blue',
                                                    }}>
                                                    070.443.0919
                                                </Typography>
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={600}
                                                    color="rgba(0,0,0,0.6)">
                                                    (Zalo | 08:00 - 21:30)
                                                </Typography>
                                            </div>
                                        }
                                    />
                                ),
                            },
                            {
                                id: 'open-time',
                                ListItemProps: { disablePadding: true },
                                icon: (
                                    <ScheduleIcon
                                        fontSize="small"
                                        sx={{ color: 'darkblue' }}
                                    />
                                ),
                                children: (
                                    <ListItemText
                                        primary={
                                            <Typography
                                                fontSize={14}
                                                color="darkblue">
                                                Thứ 2 - Chủ nhật | 06:30 - 22:15
                                            </Typography>
                                        }
                                    />
                                ),
                            },
                            {
                                id: 'address-contact',
                                ListItemProps: { disablePadding: true },
                                icon: (
                                    <PinDropIcon
                                        fontSize="small"
                                        sx={{ color: 'darkblue' }}
                                    />
                                ),
                                children: (
                                    <ListItemText
                                        primary={
                                            <Typography
                                                aria-label="googlemap-icon"
                                                component="a"
                                                href="https://www.google.com/maps/place/Nh%C3%A0+Thu%E1%BB%91c+T%C6%B0+Nh%C3%A2n+Kh%C3%A1nh+Trang/@10.8158692,106.5945157,16.77z/data=!4m6!3m5!1s0x31752b97cd17fb6f:0xb3ee2a6fe105213!8m2!3d10.8158685!4d106.5966005!16s%2Fg%2F11xhbyt7_"
                                                fontSize={14}
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor:
                                                            'transparent',
                                                        color: 'blue',
                                                    },
                                                    textDecoration: 'none',
                                                    color: 'darkblue',
                                                    maxWidth: '95%',
                                                }}>
                                                124 Nguyễn Thị Tú, P. Bình Hưng
                                                Hoà B, Q. Bình Tân, TP. HCM
                                            </Typography>
                                        }
                                    />
                                ),
                            },
                            {
                                id: 'social-media-contact',
                                ListItemProps: {
                                    disablePadding: true,
                                },
                                icon: (
                                    <ShareIcon
                                        fontSize="small"
                                        sx={{ color: 'darkblue' }}
                                    />
                                ),
                                children: (
                                    <ListItemText
                                        primary={
                                            <Typography fontSize={14}>
                                                Xem các bài chia sẻ hay về thuốc
                                                và bệnh tại{' '}
                                                <CustomButton
                                                    useIconButton
                                                    aria-label="facebook-icon"
                                                    component="a"
                                                    href="https://www.facebook.com/nhathuockhanhtrang/"
                                                    size="small">
                                                    <FacebookIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: '#4267b2',
                                                            '&:hover': {
                                                                color: '#4267b2',
                                                            },
                                                        }}
                                                    />
                                                </CustomButton>
                                                <CustomButton
                                                    useIconButton
                                                    aria-label="youtube-icon"
                                                    component="a"
                                                    href="https://www.youtube.com/@duocsitantam124/about"
                                                    size="small">
                                                    <YouTubeIcon
                                                        fontSize="small"
                                                        sx={{
                                                            color: 'red',
                                                            '&:hover': {
                                                                color: 'red',
                                                            },
                                                        }}
                                                    />
                                                </CustomButton>
                                            </Typography>
                                        }
                                    />
                                ),
                            },
                        ],
                    },
                    {
                        isOpenCollapse: isOpenCollases.cateLv1,
                        handleClickCollapse: () => {
                            setIsOpenCollapses((prevValue) => {
                                let updatedValue = { ...prevValue };
                                updatedValue.cateLv1 = !updatedValue.cateLv1;
                                return updatedValue;
                            });
                        },
                        subHeaderText: 'DANH MỤC',
                        listOptions: Object.entries(breadcrumbNameMapping)
                            .filter(([key]) =>
                                listMainDrawerRouter.includes(key)
                            )
                            .map(([pathname, label]) => {
                                return {
                                    onClick: () => {
                                        navigate(pathname);
                                    },
                                    children: (
                                        <ListItemText
                                            component="button"
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                                color: 'blue',
                                            }}
                                            sx={{
                                                cursor: 'pointer',
                                            }}>
                                            {label}
                                        </ListItemText>
                                    ),
                                    disablePaddingListItem: true,
                                };
                            }),
                    },
                    {
                        isOpenCollapse: isOpenCollases.about,
                        handleClickCollapse: () => {
                            setIsOpenCollapses((prevValue) => {
                                let updatedValue = { ...prevValue };
                                updatedValue.about = !updatedValue.about;
                                return updatedValue;
                            });
                        },
                        subHeaderText: 'VỀ CHÚNG TÔI',
                        listOptions: Object.entries(breadcrumbNameMapping)
                            .filter(([key]) =>
                                listCustomerServiceRouter.includes(key)
                            )
                            .map(([pathname, label]) => {
                                return {
                                    onClick: () => {
                                        navigate(pathname);
                                    },
                                    children: (
                                        <ListItemText
                                            component="button"
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                                color: 'blue',
                                            }}
                                            sx={{
                                                cursor: 'pointer',
                                            }}>
                                            {label}
                                        </ListItemText>
                                    ),
                                    disablePaddingListItem: true,
                                };
                            }),
                    },
                ].map((i, idx) => {
                    const {
                        isOpenCollapse = false,
                        subHeaderText = '',
                        handleClickCollapse = () => {},
                        listOptions = [],
                    } = i;
                    return (
                        <Fragment key={i.subHeaderText}>
                            <ListItemButton
                                sx={{ px: 0, mx: 3 }}
                                variant="text"
                                onClick={handleClickCollapse}>
                                <ListItemText
                                    primary={subHeaderText}
                                    primaryTypographyProps={{
                                        variant: 'subtitle2',
                                        color: 'darkblue',
                                        fontSize: 16,
                                    }}
                                />
                                {isOpenCollapse ? (
                                    <ExpandLessIcon />
                                ) : (
                                    <ExpandMoreIcon />
                                )}
                            </ListItemButton>
                            <CustomCollapseList
                                id={
                                    'page-footer-custom-collapse-list-' +
                                    subHeaderText
                                }
                                open={isOpenCollapse}
                                ListProps={{
                                    sx: {
                                        backgroundColor: 'aliceblue',
                                        mx: 3,
                                        borderRadius: '5px',
                                        border: '0.5px dotted rgba(0, 0, 139, 0.2)',
                                    },
                                }}
                                options={listOptions.map(
                                    ({ onClick, children, icon }) => {
                                        return {
                                            id: Math.random() * 1000,
                                            icon,
                                            children,
                                            onClick,
                                        };
                                    }
                                )}
                            />
                        </Fragment>
                    );
                })}
                <Box mb={2}>
                    <Typography
                        color="darkblue"
                        variant="subtitle2"
                        fontSize={16}
                        py={1}
                        pl={3}>
                        CHỨNG NHẬN BỞI
                    </Typography>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-evenly"
                        width="100%"
                        spacing={1}>
                        <img
                            src="../assets/images/bct.png"
                            alt="bct"
                            loading="lazy"
                            width={150}
                        />
                        <a
                            href="//www.dmca.com/Protection/Status.aspx?ID=ef027488-1508-4453-af8e-9c51203e026b&refurl=https://www.nhathuockhanhtrang.com.vn/"
                            title="DMCA.com Protection Status"
                            className="dmca-badge">
                            <img
                                src="https://images.dmca.com/Badges/dmca-badge-w100-2x1-03.png?ID=ef027488-1508-4453-af8e-9c51203e026b"
                                alt="DMCA.com Protection Status"
                                height={40}
                                width={100}
                            />
                        </a>
                    </Stack>
                </Box>
            </List>
        );
    }
    return (
        <Grid
            sx={{
                pt: 3,
                pb: 3,
                px: 5,
                color: 'darkblue',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
            }}
            container
            columnSpacing={2}
            columns={12}
            direction="row"
            justifyContent="center"
            alignItems="flex-start">
            <Grid item xs={12} sm={4} lg={3}>
                <CustomList
                    id="contact-custom-list"
                    useListItemButton={false}
                    disablePaddingListItem
                    subheader={
                        <Typography
                            color="grey"
                            variant="body2"
                            fontSize={13}
                            pb={1}
                            fontWeight={700}>
                            THÔNG TIN LIÊN HỆ
                        </Typography>
                    }
                    options={[
                        {
                            id: 'phone-contact',
                            icon: (
                                <WhatsAppIcon
                                    fontSize="small"
                                    sx={{ mt: -0.2, color: 'darkblue' }}
                                />
                            ),
                            children: (
                                <ListItemText
                                    primary={
                                        <div>
                                            <Typography
                                                component="a"
                                                href="tel:0704430919"
                                                fontSize={14}
                                                fontWeight={600}
                                                sx={{
                                                    '&:hover': {
                                                        color: 'blue',
                                                        backgroundColor:
                                                            'transparent',
                                                    },
                                                    textDecoration: 'none',
                                                    color: 'blue',
                                                }}>
                                                070.443.0919
                                            </Typography>
                                            <Typography
                                                fontSize={14}
                                                fontWeight={600}
                                                color="rgba(0,0,0,0.6)">
                                                (Zalo | 08:00 - 21:30)
                                            </Typography>
                                        </div>
                                    }
                                />
                            ),
                        },
                        {
                            id: 'open-time',
                            ListItemProps: { disablePadding: true },
                            icon: (
                                <ScheduleIcon
                                    fontSize="small"
                                    sx={{ color: 'darkblue' }}
                                />
                            ),
                            children: (
                                <ListItemText
                                    primary={
                                        <Typography
                                            fontSize={14}
                                            color="darkblue">
                                            Thứ 2 - Chủ nhật | 06:30 - 22:15
                                        </Typography>
                                    }
                                />
                            ),
                        },
                        {
                            id: 'address-contact',
                            ListItemProps: { disablePadding: true },
                            icon: (
                                <PinDropIcon
                                    fontSize="small"
                                    sx={{ color: 'darkblue' }}
                                />
                            ),
                            children: (
                                <ListItemText
                                    primary={
                                        <Typography
                                            aria-label="googlemap-icon"
                                            component="a"
                                            href="https://www.google.com/maps/place/Nh%C3%A0+Thu%E1%BB%91c+T%C6%B0+Nh%C3%A2n+Kh%C3%A1nh+Trang/@10.8158692,106.5945157,16.77z/data=!4m6!3m5!1s0x31752b97cd17fb6f:0xb3ee2a6fe105213!8m2!3d10.8158685!4d106.5966005!16s%2Fg%2F11xhbyt7_"
                                            fontSize={14}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor:
                                                        'transparent',
                                                    color: 'blue',
                                                },
                                                textDecoration: 'none',
                                                color: 'darkblue',
                                                maxWidth: '95%',
                                            }}>
                                            124 Nguyễn Thị Tú, P. Bình Hưng Hoà
                                            B, Q. Bình Tân, TP. HCM
                                        </Typography>
                                    }
                                />
                            ),
                        },
                        {
                            id: 'social-media-contact',
                            ListItemProps: {
                                disablePadding: true,
                            },
                            icon: (
                                <ShareIcon
                                    fontSize="small"
                                    sx={{ color: 'darkblue' }}
                                />
                            ),
                            children: (
                                <ListItemText
                                    primary={
                                        <Typography fontSize={14}>
                                            Xem các bài chia sẻ hay về thuốc và
                                            bệnh tại{' '}
                                            <CustomButton
                                                useIconButton
                                                aria-label="facebook-icon"
                                                component="a"
                                                href="https://www.facebook.com/nhathuockhanhtrang/"
                                                size="small">
                                                <FacebookIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: '#4267b2',
                                                        '&:hover': {
                                                            color: '#4267b2',
                                                        },
                                                    }}
                                                />
                                            </CustomButton>
                                            <CustomButton
                                                useIconButton
                                                aria-label="youtube-icon"
                                                component="a"
                                                href="https://www.youtube.com/@duocsitantam124/about"
                                                size="small">
                                                <YouTubeIcon
                                                    fontSize="small"
                                                    sx={{
                                                        color: 'red',
                                                        '&:hover': {
                                                            color: 'red',
                                                        },
                                                    }}
                                                />
                                            </CustomButton>
                                        </Typography>
                                    }
                                />
                            ),
                        },
                    ]}
                />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
                <CustomList
                    id="contact-custom-list"
                    useListItemButton={false}
                    disablePaddingListItem
                    subheader={
                        <Typography
                            color="grey"
                            variant="body2"
                            fontSize={13}
                            pb={1}
                            fontWeight={700}>
                            DANH MỤC
                        </Typography>
                    }
                    options={Object.entries(breadcrumbNameMapping)
                        .filter(([key]) => listMainDrawerRouter.includes(key))
                        .map(([pathname, label]) => {
                            return {
                                onClick: () => {
                                    navigate(pathname);
                                },
                                children: (
                                    <ListItemText
                                        component="button"
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                        }}
                                        sx={{
                                            cursor: 'pointer',
                                            ':hover': {
                                                color: 'blue',
                                            },
                                        }}>
                                        {label}
                                    </ListItemText>
                                ),
                            };
                        })}
                />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
                <CustomList
                    disablePaddingListItem
                    id="contact-custom-list"
                    useListItemButton={false}
                    subheader={
                        <Typography
                            color="grey"
                            variant="body2"
                            fontSize={13}
                            pb={1}
                            fontWeight={700}>
                            VỀ CHÚNG TÔI
                        </Typography>
                    }
                    options={Object.entries(breadcrumbNameMapping)
                        .filter(([key]) =>
                            listCustomerServiceRouter.includes(key)
                        )
                        .map(([pathname, label]) => {
                            return {
                                onClick: () => {
                                    navigate(pathname);
                                },
                                children: (
                                    <ListItemText
                                        component="button"
                                        primaryTypographyProps={{
                                            fontSize: 14,
                                        }}
                                        sx={{
                                            cursor: 'pointer',
                                            ':hover': {
                                                color: 'blue',
                                            },
                                        }}>
                                        {label}
                                    </ListItemText>
                                ),
                            };
                        })}
                />
            </Grid>
            <Grid item xs={12} sm={12} lg={3}>
                <Grid
                    container
                    columnSpacing={2}
                    columns={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-
                    start"
                    sx={{ pt: { xs: 2, lg: 0 } }}>
                    <Grid item xs={12} md={6} lg={12}>
                        <Box mb={2}>
                            <Typography
                                color="grey"
                                variant="body2"
                                fontSize={13}
                                pb={1}
                                fontWeight={700}>
                                CHỨNG NHẬN BỞI
                            </Typography>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-evenly"
                                width="100%"
                                spacing={1}>
                                <img
                                    src="../assets/images/bct.png"
                                    alt="bct"
                                    loading="lazy"
                                    width={150}
                                />
                                <a
                                    href="//www.dmca.com/Protection/Status.aspx?ID=ef027488-1508-4453-af8e-9c51203e026b&refurl=https://www.nhathuockhanhtrang.com.vn/"
                                    title="DMCA.com Protection Status"
                                    className="dmca-badge">
                                    <img
                                        src="https://images.dmca.com/Badges/dmca-badge-w100-2x1-03.png?ID=ef027488-1508-4453-af8e-9c51203e026b"
                                        alt="DMCA.com Protection Status"
                                        height={40}
                                        width={100}
                                    />
                                </a>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={12}>
                        <Stack alignItems="center" justifyContent="center">
                            <LogoBrand />
                            <Typography
                                component={'div'}
                                fontSize={14}
                                fontWeight={500}
                                color="darkblue"
                                textAlign="center"
                                sx={{ mt: 1 }}>
                                <span>Copyright</span>
                                <sup>©</sup>{' '}
                                <span>Nhà thuốc Khánh Trang 2023</span>
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
