import { useEffect, useState } from 'react';

import { Box, Stack } from '@mui/material';
import {
    Close as CloseIcon,
    Add as AddIcon,
    DeleteSweep as DeleteSweepIcon,
} from '@mui/icons-material';

import moment from 'moment';

import {
    CustomDialog,
    CustomButton,
    CustomList,
    CustomInput,
    CustomSelect,
    CustomDatePicker,
} from 'UI';

const validateFilters = (filterModel) => {
    let errors = [];
    for (let index = 0; index < filterModel.length; index++) {
        const filter = filterModel[index];
        if (
            (filter.typeofField === 'string' ||
                filter.typeofField === 'number' ||
                filter.typeofField === 'date') &&
            !filter.value
        ) {
            errors.push({ errorMessage: 'Chưa nhập giá trị!', index });
        }
        if (filter.typeofField === 'array' && filter.value.length === 0) {
            errors.push({ errorMessage: 'Chưa chọn giá trị!', index });
        }

        if (
            filter.typeofField === 'date' &&
            moment(filter.value).year() > 2099
        ) {
            errors.push({
                errorMessage: 'Không được vượt quá năm 2099!',
                index,
            });
        }
        if (
            filter.typeofField === 'date' &&
            moment(filter.value).year() < 2000
        ) {
            errors.push({
                errorMessage: 'Không được nhỏ hơn năm 2000!',
                index,
            });
        }
    }
    return errors;
};

function CustomInputWithProps({
    name = 'name',
    value,
    onChange = () => {},
    label = '',

    ...otherProps
}) {
    return (
        <CustomInput
            id={`${name}-custom-input`}
            name={name}
            label={label}
            margin="none"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={value}
            onChange={onChange}
            {...otherProps}
        />
    );
}
function CustomSelectWithProps({
    name = 'name',
    options = [],
    multiple = false,
    value,
    onChange = () => {},
    convertArrOptions = (value) => ({
        value,
        label: value,
    }),
    label = '',
    ...otherProps
}) {
    return (
        <CustomSelect
            name={name}
            label={label}
            id={`${name}-custom-select`}
            InputLabelProps={{ shrink: true }}
            margin="none"
            size="small"
            fullWidth
            options={options}
            convertArrOptions={convertArrOptions}
            multiple={multiple}
            value={value}
            onChange={onChange}
            {...(value.length > 0 &&
                !['connectType', 'field', 'type'].includes(name) && {
                    endAdornment: (
                        <Box sx={{ mr: 2 }}>
                            <CustomButton
                                id={`${Math.random() * 1000}-clearIcon-button`}
                                variant="base"
                                color="default"
                                useIconButton
                                onClick={() => {
                                    if (multiple) {
                                        onChange([]);
                                    } else {
                                        onChange('');
                                    }
                                }}>
                                <CloseIcon fontSize="small" />
                            </CustomButton>
                        </Box>
                    ),
                })}
            {...otherProps}
        />
    );
}
function RenderValueInput({
    field = '',
    type = '',
    typeofField = '',
    value = '',
    options = [],
    getOptionLabel = (value) => value,
    onChange = () => {},
    handleSubmit = () => {},
    error = '',
    resetError = () => {},
    ...otherProps
}) {
    let sameProps = {
        variant: 'standard',
        name: 'value',
        label: 'Giá trị',
    };
    if (!typeofField) {
        return (
            <CustomInputWithProps
                {...sameProps}
                value={value}
                onChange={() => {}}
                disabled
            />
        );
    } else if (typeofField === 'array' || typeofField === 'boolean') {
        return (
            <CustomSelectWithProps
                {...sameProps}
                value={value === '' ? [] : value}
                onChange={(newValue) => {
                    onChange(newValue);
                    if (error) {
                        resetError();
                    }
                }}
                error={error}
                multiple
                options={options}
                disabled={
                    !type ||
                    !field ||
                    (typeofField !== 'array' && typeofField !== 'boolean')
                }
                convertArrOptions={(option) => ({
                    value: option,
                    label: getOptionLabel(option),
                })}
            />
        );
    } else if (typeofField === 'string' || typeofField === 'number') {
        return (
            <CustomInputWithProps
                {...sameProps}
                value={value}
                onChange={(newValue) => {
                    onChange(newValue);
                    if (error) {
                        resetError();
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmit();
                    }
                }}
                error={error}
                disabled={
                    !field ||
                    !type ||
                    (typeofField !== 'string' && typeofField !== 'number')
                }
                {...(typeofField === 'number' && {
                    type: 'number',
                    inputProps: {
                        min: 0,
                    },
                })}
            />
        );
    } else if (typeofField === 'date') {
        return (
            <Box sx={{ mb: '4px', width: '100%' }}>
                <CustomDatePicker
                    id="value-custom-date-picker"
                    {...sameProps}
                    onChange={(newValue) => {
                        onChange(newValue);
                        if (error) {
                            resetError();
                        }
                    }}
                    InputLabelProps={{ shrink: true }}
                    error={error}
                    value={value}
                    disabled={!field || !type || typeofField !== 'date'}
                    margin="none"
                    size="small"
                />
            </Box>
        );
    } else return null;
}
export default function CustomFilterDialog({
    open = false,
    onClose = () => {},
    initialValue = [],
    onSubmit = () => {},
    filterData = {},
    ...otherProps
}) {
    const [filterModel, setFilterModel] = useState([]);
    // apply current filter if present, only run once!
    useEffect(() => {
        let getData;
        if (open) {
            getData = setTimeout(() => {
                setFilterModel(
                    initialValue.length > 0
                        ? initialValue
                        : [
                              {
                                  id: Math.random() * 1000,
                                  connectType: 'and',
                                  field: Object.keys(filterData)[0],
                                  typeofField:
                                      filterData[Object.keys(filterData)[0]]
                                          .typeofField,
                                  type: filterData[Object.keys(filterData)[0]]
                                      .initialType,
                                  value: filterData[Object.keys(filterData)[0]]
                                      .initialValue,
                              },
                          ]
                );
            }, 200);
        }
        return () => {
            clearTimeout(getData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [errors, setErrors] = useState([]);

    const handleSubmit = () => {
        let validation = validateFilters(filterModel);
        if (validation.length > 0) {
            setErrors(validation);
            return;
        } else {
            onSubmit(filterModel);
            onClose();
        }
    };
    if (open) {
        return (
            <CustomDialog
                id="filter-data-custom-dialog"
                title={'Bộ lọc dữ liệu'}
                open={open}
                onClose={onClose}
                maxWidth="md"
                hideNoButton
                labelYesButon={filterModel.length > 0 ? 'Lọc' : 'Không lọc'}
                onYes={handleSubmit}>
                <CustomList
                    id="filter-list-custom-list"
                    // subheader="Nhấn Thêm để tạo bộ lọc với nhiều điều kiện"
                    disablePaddingListItem
                    useListItemButton={false}
                    options={filterModel.map(
                        (
                            {
                                id,
                                connectType = 'and',
                                field,
                                typeofField,
                                type,
                                value,
                            },
                            index
                        ) => {
                            let errMess = errors.filter(
                                (i) => i.index === index
                            )?.[0]?.errorMessage;
                            let showError = Boolean(errMess);
                            return {
                                ListItemProps: {
                                    sx: {
                                        mr: 1,
                                    },
                                    key: id, // need to avoid re-render all options (which leads to lost focus on CustomInput)
                                },
                                children: (
                                    <Stack
                                        py={1}
                                        ml={connectType === 'or' ? 10 : 0}
                                        sx={{ borderLeft: '2px solid #EDEDED' }}
                                        direction="row"
                                        alignItems={
                                            showError ? 'center' : 'flex-end'
                                        }
                                        width="100%"
                                        minWidth="600px">
                                        <CustomButton
                                            id="delete-custom-button"
                                            useIconButton
                                            onClick={() => {
                                                setFilterModel((data) => {
                                                    let updatedData = [...data];
                                                    return updatedData.filter(
                                                        (i) => i.id !== id
                                                    );
                                                });
                                            }}
                                            size="small">
                                            <CloseIcon fontSize="small" />
                                        </CustomButton>
                                        {index === 0 ? (
                                            <Box
                                                width={
                                                    filterModel.length === 1
                                                        ? 0
                                                        : 300
                                                }
                                                mx={1}>
                                                {filterModel.length > 1 && (
                                                    <CustomSelectWithProps
                                                        variant="standard"
                                                        name={'connectType'}
                                                        options={['and', 'or']}
                                                        multiple={false}
                                                        disabled
                                                        convertArrOptions={(
                                                            option
                                                        ) => ({
                                                            value: option,
                                                            label:
                                                                option === 'and'
                                                                    ? 'VÀ'
                                                                    : option ===
                                                                      'or'
                                                                    ? 'HOẶC'
                                                                    : '',
                                                        })}
                                                        value={'and'}
                                                        onChange={() => {}}
                                                        helperText={
                                                            showError ? ' ' : ''
                                                        }
                                                    />
                                                )}
                                            </Box>
                                        ) : (
                                            <Box width={300} mx={1}>
                                                <CustomSelectWithProps
                                                    variant="standard"
                                                    name={'connectType'}
                                                    options={['and', 'or']}
                                                    multiple={false}
                                                    convertArrOptions={(
                                                        option
                                                    ) => ({
                                                        value: option,
                                                        label:
                                                            option === 'and'
                                                                ? 'VÀ'
                                                                : option ===
                                                                  'or'
                                                                ? 'HOẶC'
                                                                : '',
                                                    })}
                                                    value={connectType}
                                                    onChange={(
                                                        newConnectType
                                                    ) => {
                                                        setFilterModel(
                                                            (data) => {
                                                                let updatedData =
                                                                    [...data];
                                                                updatedData =
                                                                    updatedData.map(
                                                                        (
                                                                            item
                                                                        ) =>
                                                                            item.id ===
                                                                            id
                                                                                ? {
                                                                                      ...item,
                                                                                      connectType:
                                                                                          newConnectType,
                                                                                  }
                                                                                : item
                                                                    );
                                                                return updatedData;
                                                            }
                                                        );
                                                    }}
                                                    helperText={
                                                        showError ? ' ' : ''
                                                    }
                                                />
                                            </Box>
                                        )}
                                        <CustomSelectWithProps
                                            label="Tên mục"
                                            variant="standard"
                                            name={'field'}
                                            multiple={false}
                                            options={Object.keys(filterData)}
                                            convertArrOptions={(option) => ({
                                                value: option,
                                                label:
                                                    filterData?.[option]
                                                        ?.label || '',
                                            })}
                                            value={field}
                                            onChange={(newField) => {
                                                if (newField === field) {
                                                    return;
                                                }
                                                setFilterModel((data) => {
                                                    let updatedData = [...data];
                                                    let typeofField =
                                                        filterData?.[newField]
                                                            ?.typeofField || '';
                                                    let type =
                                                        filterData?.[newField]
                                                            ?.initialType || '';
                                                    let value =
                                                        filterData?.[newField]
                                                            ?.initialValue ||
                                                        '';
                                                    updatedData =
                                                        updatedData.map(
                                                            (item) =>
                                                                item.id === id
                                                                    ? {
                                                                          ...item,
                                                                          field: newField,
                                                                          typeofField,
                                                                          type,
                                                                          value,
                                                                      }
                                                                    : item
                                                        );
                                                    return updatedData;
                                                });
                                                if (showError) {
                                                    setErrors((errors) => {
                                                        let updatedErrors = [
                                                            ...errors,
                                                        ];
                                                        updatedErrors =
                                                            updatedErrors.filter(
                                                                (i) =>
                                                                    i.index !==
                                                                    index
                                                            );
                                                        return updatedErrors;
                                                    });
                                                }
                                            }}
                                            helperText={showError ? ' ' : ''}
                                        />
                                        <CustomSelectWithProps
                                            label="So sánh"
                                            variant="standard"
                                            name={'type'}
                                            multiple={false}
                                            options={
                                                typeofField === 'string'
                                                    ? [
                                                          'contains',
                                                          'notContains',
                                                          'startsWith',
                                                          'endsWith',
                                                          'matchExact',
                                                      ]
                                                    : typeofField === 'number'
                                                    ? [
                                                          '=',
                                                          '!=',
                                                          '>',
                                                          '>=',
                                                          '<',
                                                          '<=',
                                                      ]
                                                    : typeofField === 'date'
                                                    ? [
                                                          'isSameDate',
                                                          'isNotSameDate',
                                                          'isBeforeDate',
                                                          'isAfterDate',
                                                      ]
                                                    : typeofField === 'array' ||
                                                      typeofField === 'boolean'
                                                    ? ['in', 'nin']
                                                    : []
                                            }
                                            disabled={!field || !typeofField}
                                            convertArrOptions={(option) => ({
                                                value: option,
                                                label:
                                                    option === 'contains'
                                                        ? 'có chứa'
                                                        : option ===
                                                          'notContains'
                                                        ? 'không chứa'
                                                        : option ===
                                                          'startsWith'
                                                        ? 'bắt đầu với'
                                                        : option === 'endsWith'
                                                        ? 'kết thúc với'
                                                        : option ===
                                                          'matchExact'
                                                        ? 'bằng đúng'
                                                        : option === '!='
                                                        ? 'không ='
                                                        : option === 'in'
                                                        ? 'gồm'
                                                        : option === 'nin'
                                                        ? 'không gồm'
                                                        : option ===
                                                          'isSameDate'
                                                        ? 'là ngày'
                                                        : option ===
                                                          'isNotSameDate'
                                                        ? 'không phải ngày'
                                                        : option ===
                                                          'isBeforeDate'
                                                        ? 'trước ngày'
                                                        : option ===
                                                          'isAfterDate'
                                                        ? 'sau ngày'
                                                        : option,
                                            })}
                                            value={type}
                                            onChange={(newType) => {
                                                setFilterModel((data) => {
                                                    let updatedData = [...data];
                                                    updatedData =
                                                        updatedData.map(
                                                            (item) =>
                                                                item.id === id
                                                                    ? {
                                                                          ...item,
                                                                          type: newType,
                                                                      }
                                                                    : item
                                                        );
                                                    return updatedData;
                                                });
                                            }}
                                            helperText={showError ? ' ' : ''}
                                        />
                                        <RenderValueInput
                                            key={id}
                                            field={field}
                                            type={type}
                                            value={value}
                                            typeofField={typeofField}
                                            options={
                                                filterData?.[field]?.options ||
                                                []
                                            } // use for typeofField === 'array' || typeofField === 'boolean'
                                            getOptionLabel={
                                                filterData?.[field]
                                                    ?.getOptionLabel ||
                                                ((value) => value)
                                            } // use for typeofField === 'array' || typeofField === 'boolean'
                                            onChange={(newValue) => {
                                                if (!typeofField) {
                                                    return;
                                                } else {
                                                    setFilterModel((data) => {
                                                        let updatedData = [
                                                            ...data,
                                                        ];
                                                        updatedData =
                                                            updatedData.map(
                                                                (item) =>
                                                                    item.id ===
                                                                    id
                                                                        ? {
                                                                              ...item,
                                                                              value: newValue,
                                                                          }
                                                                        : item
                                                            );
                                                        return updatedData;
                                                    });
                                                }
                                            }}
                                            handleSubmit={handleSubmit}
                                            error={errMess || ''}
                                            resetError={() =>
                                                setErrors((errors) => {
                                                    let updatedErrors = [
                                                        ...errors,
                                                    ];
                                                    updatedErrors =
                                                        updatedErrors.filter(
                                                            (i) =>
                                                                i.index !==
                                                                index
                                                        );
                                                    return updatedErrors;
                                                })
                                            }
                                        />
                                    </Stack>
                                ),
                            };
                        }
                    )}
                />
                <Stack
                    minWidth={600}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}>
                    <CustomButton
                        id="remove-filters-button"
                        variant="outlined"
                        size="small"
                        color="info"
                        disabled={filterModel.length === 0}
                        onClick={() => {
                            setFilterModel([]);
                        }}
                        startIcon={<DeleteSweepIcon fontSize="small" />}>
                        Xóa tất cả
                    </CustomButton>
                    <CustomButton
                        id="add-filter-button"
                        size="small"
                        color="info"
                        onClick={() => {
                            setFilterModel((data) => {
                                let updatedData = [...data];
                                return [
                                    ...updatedData,
                                    {
                                        id: Math.random() * 1000,
                                        field: Object.keys(filterData)[0],
                                        typeofField:
                                            filterData[
                                                Object.keys(filterData)[0]
                                            ].typeofField,
                                        type: filterData[
                                            Object.keys(filterData)[0]
                                        ].initialType,
                                        value: filterData[
                                            Object.keys(filterData)[0]
                                        ].initialValue,
                                    },
                                ];
                            });
                        }}
                        startIcon={<AddIcon fontSize="small" />}>
                        Thêm
                    </CustomButton>
                </Stack>
            </CustomDialog>
        );
    } else return null;
}
