import { useState } from 'react';

import { AppBar, Typography, Stack, useMediaQuery, Box } from '@mui/material';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Menu as MenuIcon,
    Person as PersonIcon,
    Logout as LogoutIcon,
    ShoppingBasket as ShoppingBasketIcon,
    PinDrop as PinDropIcon,
    EmojiEvents as EmojiEventsIcon,
    ArrowBackIosNew as ArrowBackIosNewIcon,
    Face as FaceIcon,
    Face2 as Face2Icon,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { systemActions } from 'store/system-slice.js';

import SearchAppbar from '../SearchAppbar.jsx';
import { CustomMenu, CustomAvatar, CustomButton } from 'UI';

const customTheme = (outerTheme) =>
    createTheme({
        components: {
            MuiTypography: {
                styleOverrides: {
                    noWrap(styles) {
                        return {
                            whiteSpace: 'initial',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: String(
                                styles.ownerState['data-lines'] || '3'
                            ),
                            WebkitBoxOrient: 'vertical',
                        };
                    },
                },
            },
        },
    });

const StyledAccountAvatar = ({ gender = 'male' }) => {
    return (
        <CustomAvatar size={28}>
            {gender === 'male' ? (
                <FaceIcon
                    sx={{
                        color: 'rgba(0, 0, 139, 0.75)',
                        width: 26,
                        height: 26,
                    }}
                />
            ) : (
                <Face2Icon
                    sx={{
                        color: 'rgba(0, 0, 139, 0.75)',
                        width: 26,
                        height: 26,
                    }}
                />
            )}
        </CustomAvatar>
    );
};
const StyledAccountName = ({ children, ...otherProps }) => {
    const theme = useTheme();
    return (
        <ThemeProvider theme={customTheme(theme)}>
            <Typography
                variant="caption"
                color="darkblue"
                noWrap
                data-lines={'2'}
                sx={{
                    textTransform: 'initial',
                    textAlign: 'center',
                    height: 34,
                    maxWidth: 80,
                    lineHeight: 1.2,
                }}>
                {children}
            </Typography>
        </ThemeProvider>
    );
};

export default function SectionAppbar({
    route = 'shop',
    handleLogin = () => {},
    toggleDrawer = () => {},
    handleLogout = () => {},
    ...props
}) {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo.value);
    let {
        _id = null,
        username = '',
        gender = 'male',
        tel = '',
        rewardPoint = null,
    } = userInfo;

    const navigate = useNavigate();
    const theme = useTheme();
    const biggerMD = useMediaQuery(theme.breakpoints.up('md')); // >= 800

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const openMenu = Boolean(menuAnchorEl);

    const [openSearch, setOpenSearch] = useState(false);

    const mobileAppbar = (
        <Stack
            direction="row"
            justifyContent="space-between"
            px={2}
            pb={1}
            pt={{ xs: 1, md: 0 }}
            width="100%"
            sx={{
                display: { xs: openSearch ? 'none' : 'flex', sm: 'flex' },
            }}>
            <Stack justifyContent="center" alignItems="center">
                <CustomButton
                    styleProps={{ p: 0 }}
                    size="small"
                    useIconButton
                    onClick={toggleDrawer}>
                    <MenuIcon sx={{ color: 'darkblue' }} />
                </CustomButton>
                <Typography variant="caption" color="darkblue">
                    Menu
                </Typography>
            </Stack>
            <CustomAvatar
                variant="square"
                src="../assets/images/khanhtranglogo-02.png"
                styleProps={{
                    cursor: 'pointer',
                    width: 220,
                    height: 80,
                }}
                onClick={() => {
                    if (route === 'admin') {
                        navigate('/admin');
                    } else {
                        navigate('/');
                    }
                }}
            />
            <Stack justifyContent="center" alignItems="center" spacing={0.5}>
                <CustomButton
                    id="account-avatar-icon-button"
                    color="primary"
                    useIconButton
                    onClick={(event) => {
                        if (!_id) {
                            return handleLogin();
                        }
                        setMenuAnchorEl(event.currentTarget);
                    }}
                    styleProps={{ p: 0 }}
                    aria-controls={openMenu ? 'avatar-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}>
                    {_id ? (
                        <StyledAccountAvatar gender={gender} />
                    ) : (
                        <PersonIcon sx={{ color: 'darkblue' }} />
                    )}
                </CustomButton>
                <StyledAccountName>
                    {_id ? username || tel : 'Đăng nhập'}
                </StyledAccountName>
            </Stack>
        </Stack>
    );
    const desktopAppbar = (
        <Stack
            sx={{
                maxWidth: 1260,
                mx: 'auto',
                px: { xs: 0, sm: 3 },
            }}
            direction={'row'}
            justifyContent="center"
            alignItems="flex-start"
            width="100%"
            mt={1}
            pb={1}>
            <Box sx={{ overflow: 'hidden' }}>
                <CustomAvatar
                    src="../assets/images/khanhtranglogo-02.png"
                    variant="square"
                    onClick={() => {
                        if (route === 'admin') {
                            navigate('/admin');
                        } else {
                            navigate('/');
                        }
                    }}
                    styleProps={{
                        marginLeft: '-35px',
                        cursor: 'pointer',
                        width: 200,
                        height: 80,
                    }}
                />
            </Box>
            <SearchAppbar open={openSearch} onOpen={setOpenSearch} />
            <Stack
                justifyContent="center"
                alignItems="center"
                flexShrink={0}
                mt={2}
                mx={1}>
                <CustomButton
                    id="account-avatar-icon-button"
                    color="primary"
                    useIconButton
                    onClick={(event) => {
                        if (!_id) {
                            return handleLogin();
                        }
                        setMenuAnchorEl(event.currentTarget);
                    }}
                    styleProps={{ p: 0 }}
                    aria-controls={openMenu ? 'avatar-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}>
                    {_id ? (
                        <StyledAccountAvatar gender={gender} />
                    ) : (
                        <PersonIcon sx={{ color: 'darkblue' }} />
                    )}
                </CustomButton>
                <StyledAccountName>
                    {_id ? username || tel : 'Đăng nhập'}
                </StyledAccountName>
            </Stack>
        </Stack>
    );
    const menuAvatar = (
        <CustomMenu
            id="account-menu"
            anchorEl={menuAnchorEl}
            open={openMenu}
            onClose={() => setMenuAnchorEl(null)}
            anchorPosition={{ left: 15, top: 0 }}
            options={(rewardPoint
                ? [
                      {
                          value: 'reward-point',
                          onClick: () => {},
                          icon: (
                              <EmojiEventsIcon
                                  fontSize="small"
                                  sx={{ color: 'orange' }}
                              />
                          ),
                          children: `Điểm tích lũy: ${Number(
                              rewardPoint
                          ).toLocaleString()} điểm`,
                      },
                      {
                          value: 'divider-1',
                          useDivider: true,
                      },
                  ]
                : []
            ).concat([
                // {
                //     value: 'account-information',
                //     onClick: () => {
                //         // navigate('/tai-khoan/thong-tin-tai-khoan');
                //         setMenuAnchorEl(null);
                //     },
                //     icon: (
                //         <PersonIcon fontSize="small" sx={{ color: 'orange' }} />
                //     ),
                //     children: 'Thông tin tài khoản',
                // },
                // {
                //     value: 'shipping-address',
                //     onClick: () => {
                //         // navigate('/tai-khoan/dia-chi-nhan-hang');
                //         setMenuAnchorEl(null);
                //     },
                //     icon: (
                //         <PinDropIcon
                //             fontSize="small"
                //             sx={{ color: 'orange' }}
                //         />
                //     ),
                //     children: 'Địa chỉ nhận hàng',
                // },
                // {
                //     value: 'shipping-history',
                //     onClick: () => {
                //         // navigate('/tai-khoan/lich-su-mua-hang');
                //         setMenuAnchorEl(null);
                //     },
                //     icon: (
                //         <ShoppingBasketIcon
                //             fontSize="small"
                //             sx={{ color: 'orange' }}
                //         />
                //     ),
                //     children: 'Lịch sử mua hàng',
                // },
                // {
                //     value: 'divider-2',
                //     useDivider: true,
                // },
                {
                    value: 'logout',
                    onClick: () => {
                        handleLogout();
                        setMenuAnchorEl(null);
                    },
                    icon: (
                        <LogoutIcon fontSize="small" sx={{ color: 'orange' }} />
                    ),
                    children: 'Đăng xuất',
                },
            ])}
        />
    );

    return (
        <AppBar
            component="nav"
            sx={{
                '&.MuiAppBar-root': {
                    backgroundColor: openSearch ? 'aliceblue' : 'white',
                    top: 0,
                },
            }}
            elevation={0}
            position="absolute">
            {biggerMD ? desktopAppbar : mobileAppbar}
            {!biggerMD && (
                <Stack
                    p={2}
                    pt={openSearch ? { xs: 2, sm: 0 } : 0}
                    direction="row"
                    alignItems="center"
                    spacing={1}>
                    <CustomButton
                        id="exit-search-appbar-custom-button"
                        variant="base"
                        styleProps={{
                            display: {
                                xs: openSearch ? 'flex' : 'none',
                                sm: 'none',
                            },
                        }}
                        onClick={() => {
                            dispatch(systemActions.toggleDimBackground(false));
                            document.body.style.overflow = '';
                            setOpenSearch(false);
                        }}
                        useIconButton>
                        <ArrowBackIosNewIcon
                            fontSize="small"
                            sx={{ color: 'darkblue' }}
                        />
                    </CustomButton>
                    <SearchAppbar open={openSearch} onOpen={setOpenSearch} />
                </Stack>
            )}
            {openMenu && menuAvatar}
        </AppBar>
    );
}
