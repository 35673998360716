import { lazy } from 'react';

import { loader as pageProductLoader } from '../shop/products/PageProductList.jsx';
import { loader as pageArticleLoader } from '../shop/articles/PageArticleList.jsx';
import { loader as pageProductItemLoader } from '../shop/items/PageProductItem.jsx';
import { loader as pageArticleItemLoader } from '../shop/items/PageArticleItem.jsx';

import ShopMainScreen from '../layout/main/ShopMainScreen.jsx';
import AdminMainScreen from '../layout/main/AdminMainScreen.jsx';

import { checkAuthLoader, getAuthToken } from 'utils/auth.js';

const PageHome = lazy(() => import('../shop/home/PageHome.jsx'));
const Page404 = lazy(() => import('../shop/404/Page404.jsx'));
const PageProductList = lazy(() =>
    import('../shop/products/PageProductList.jsx')
);
const PageArticleList = lazy(() =>
    import('../shop/articles/PageArticleList.jsx')
);
const PageProductItem = lazy(() => import('../shop/items/PageProductItem.jsx'));
const PageArticleItem = lazy(() => import('../shop/items/PageArticleItem.jsx'));
const PageSearch = lazy(() => import('../shop/search/PageSearch.jsx'));

const PagePolicy = lazy(() => import('../shop/policy/PagePolicy.jsx'));

const Home = lazy(() => import('../admin/home/Home.js'));

const StaffManagement = lazy(() =>
    import('../admin/staff/StaffManagement.jsx')
);
const ComprodManagement = lazy(() =>
    import('../admin/comprod/ComprodManagement.jsx')
);
const SalaryManagement = lazy(() =>
    import('../admin/salary/SalaryManagement.jsx')
);
const PageStoreManagement = lazy(() =>
    import('../admin/shop-management/homepage/PageStoreManagement.jsx')
);
const MarketManagement = lazy(() =>
    import('../admin/shop-management/markets/MarketManagement.jsx')
);
const ProdDataManagement = lazy(() =>
    import('../admin/shop-management/products/ProdDataManagement.jsx')
);
const ManufactureManagement = lazy(() =>
    import('../admin/shop-management/manufactures/ManufactureManagement.jsx')
);
const ArticleDataManagement = lazy(() =>
    import('../admin/shop-management/articles/ArticleDataManagement.jsx')
);
const KeywordManagement = lazy(() =>
    import('../admin/shop-management/keywords/KeywordManagement.jsx')
);
const IngredientManagement = lazy(() =>
    import('../admin/shop-management/ingredients/IngredientManagement.jsx')
);

export const routerConfig = [
    {
        path: '/',
        element: <ShopMainScreen />,
        errorElement: <Page404 />,
        id: 'shopRoot',
        loader: getAuthToken,
        children: [
            {
                path: 'khach-hang-than-thiet',
                element: <PagePolicy />,
            },
            {
                path: 'quy-che-hoat-dong',
                element: <PagePolicy />,
            },
            {
                path: 'chinh-sach-thanh-toan',
                element: <PagePolicy />,
            },
            {
                path: 'chinh-sach-giao-hang',
                element: <PagePolicy />,
            },
            {
                path: 'chinh-sach-doi-tra-hang',
                element: <PagePolicy />,
            },
            {
                path: 'chinh-sach-bao-mat',
                element: <PagePolicy />,
            },
            {
                path: 'chinh-sach-thu-thap-va-xu-ly-du-lieu',
                element: <PagePolicy />,
            },
            {
                path: 'bai-viet-suc-khoe',
                element: <PageArticleList />,
                loader: pageArticleLoader,
            },
            {
                path: 'bai-viet-suc-khoe/:subCategoryPath',
                element: <PageArticleList />,
                loader: pageArticleLoader,
            },
            {
                path: ':cateLv1Path',
                element: <PageProductList />,
                loader: pageProductLoader,
            },
            {
                path: ':cateLv1Path/:subCategoryPath',
                element: <PageProductList />,
                loader: pageProductLoader,
            },
            {
                path: '/san-pham/:itemSlug',
                element: <PageProductItem />,
                loader: pageProductItemLoader,
            },
            {
                path: '/bai-viet/:itemSlug',
                element: <PageArticleItem />,
                loader: pageArticleItemLoader,
            },
            {
                path: '/tim-kiem',
                element: <PageSearch />,
            },
            { path: '*', element: <Page404 /> },
            {
                path: '/',
                element: <PageHome />,
            },
        ],
    },
    {
        path: '/admin',
        element: <AdminMainScreen />,
        errorElement: <Page404 />,
        id: 'adminRoot',
        loader: getAuthToken,
        children: [
            {
                path: 'homepage',
                element: <PageStoreManagement />,
                loader: checkAuthLoader,
            },
            {
                path: 'market',
                element: <MarketManagement />,
                loader: checkAuthLoader,
            },
            {
                path: 'product-item',
                element: <ProdDataManagement />,
                loader: checkAuthLoader,
            },
            {
                path: 'manufacture',
                element: <ManufactureManagement />,
                loader: checkAuthLoader,
            },
            {
                path: 'article-item',
                element: <ArticleDataManagement />,
                loader: checkAuthLoader,
            },
            {
                path: 'keyword',
                element: <KeywordManagement />,
                loader: checkAuthLoader,
            },
            {
                path: 'ingredient',
                element: <IngredientManagement />,
                loader: checkAuthLoader,
            },
            {
                path: 'staff',
                element: <StaffManagement />,
                loader: checkAuthLoader,
            },
            {
                path: 'commission-products',
                element: <ComprodManagement />,
                loader: checkAuthLoader,
            },
            {
                path: 'salary',
                element: <SalaryManagement />,
                loader: checkAuthLoader,
            },
            { path: '*', element: <Page404 /> },
            { path: '', element: <Home />, loader: checkAuthLoader },
        ],
    },
];
