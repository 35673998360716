import { useState, useEffect } from 'react';
import {
    Stack,
    Box,
    Typography,
    Grid,
    Link,
    useMediaQuery,
    Tooltip,
    Card,
    CardHeader,
    Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
    ContentCopy as ContentCopyIcon,
    ZoomIn as ZoomInIcon,
    ZoomOut as ZoomOutIcon,
    FormatListBulleted as FormatListBulletedIcon,
} from '@mui/icons-material';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import moment from 'moment';
import {
    useNavigate,
    useParams,
    useLoaderData,
    Link as RouterLink,
} from 'react-router-dom';
import domain from 'config/domain.js';

import {
    CustomHelmet,
    CustomTable,
    CustomDialog,
    CustomChip,
    CustomAvatar,
    CustomButton,
    CustomToggleButton,
    CustomList,
} from 'UI';

import SectionBreadcrumbs from '../../layout/section/SectionBreadcrumbs.jsx';
import Page404 from '../404/Page404.jsx';

import Commitment from '../home/Commitment.jsx';
import PageItemDetails from './PageItemDetails.js';
import RecentWatchProd from './RecentWatchProd.jsx';
import SimilarProd from './SimilarProd.jsx';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function WatchImagesCustomDialog({
    title = '',
    open = false,
    onClose = () => {},
    images = [],
    ...otherProps
}) {
    const theme = useTheme();
    const biggerLG = useMediaQuery(theme.breakpoints.up('lg')); // >= 1000
    const [activeStep, setActiveStep] = useState(0);
    const [isZoomed, setIsZoomed] = useState(false);
    const handleNext = () => {
        if (activeStep === images.length - 1) return;
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) return;
        setActiveStep(activeStep - 1);
    };

    return (
        <CustomDialog
            id="watch-images-custom-dialog"
            title={isZoomed ? '' : title}
            open={open}
            onClose={onClose}
            hideNoButton
            hideYesButton
            children={
                <Stack spacing={2} alignItems="center">
                    <Stack
                        direction={{ xs: 'column', lg: 'row' }}
                        alignItems="flex-start"
                        spacing={2}
                        sx={{
                            ...(isZoomed
                                ? {
                                      minHeight: '100%',
                                      width: 'auto',
                                  }
                                : {
                                      maxHeight: '475px',
                                      width: { xs: 'auto', sm: '460px' },
                                  }),
                        }}>
                        <Box
                            p={3}
                            sx={{
                                position: 'relative',
                                borderRadius: { xs: 0, lg: '15px' },
                                overflow: 'hidden',
                            }}>
                            {biggerLG && (
                                <CustomButton
                                    id={`arrow-left-custom-button-${
                                        Math.random() * 1000
                                    }`}
                                    useIconButton
                                    size="small"
                                    variant="base"
                                    onClick={handleBack}
                                    styleProps={{
                                        position: 'absolute',
                                        left: 5,
                                        border: 'none',
                                        boxShadow: 1,
                                        top: '50%',
                                        zIndex: 1,
                                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                        color: 'white',
                                        ':hover': {
                                            backgroundColor:
                                                'rgba(0, 0, 0, 0.3)',
                                            border: 'none',
                                            color: 'white',
                                        },
                                        p: 0,
                                    }}>
                                    <KeyboardArrowLeftIcon fontSize="large" />
                                </CustomButton>
                            )}
                            {biggerLG && (
                                <CustomButton
                                    id={`arrow-right-custom-button-${
                                        Math.random() * 1000
                                    }`}
                                    useIconButton
                                    size="small"
                                    variant="base"
                                    onClick={handleNext}
                                    styleProps={{
                                        position: 'absolute',
                                        right: 5,
                                        border: 'none',
                                        boxShadow: 1,
                                        top: '50%',
                                        zIndex: 1,
                                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                        color: 'white',
                                        ':hover': {
                                            backgroundColor:
                                                'rgba(0, 0, 0, 0.3)',
                                            border: 'none',
                                            color: 'white',
                                        },
                                        p: 0,
                                    }}>
                                    <KeyboardArrowRightIcon fontSize="large" />
                                </CustomButton>
                            )}
                            <AutoPlaySwipeableViews
                                interval={5000}
                                axis={'x'}
                                autoplay={false}
                                index={activeStep}
                                onChangeIndex={(index) => {
                                    setActiveStep(index);
                                }}>
                                {images.map((src) => {
                                    return (
                                        <CustomAvatar
                                            key={Math.random() * 1000}
                                            variant="rounded"
                                            loading="lazy"
                                            styleProps={{
                                                width: 'auto',
                                                height: '100%',
                                            }}
                                            src={src}
                                            alt={
                                                'product-image-' +
                                                Math.random() * 1000
                                            }
                                        />
                                    );
                                })}
                            </AutoPlaySwipeableViews>
                        </Box>
                    </Stack>
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 10,
                            bottom: isZoomed ? 10 : 120,
                        }}>
                        <CustomButton
                            variant="base"
                            id="zoom-image-custom-button"
                            onClick={() => setIsZoomed((v) => !v)}
                            useIconButton>
                            {isZoomed ? <ZoomOutIcon /> : <ZoomInIcon />}
                        </CustomButton>
                        <CustomChip
                            color="darkblue"
                            variant="filled"
                            label={activeStep + 1 + '/' + images.length}
                        />
                    </Box>
                    {!isZoomed && (
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            spacing={1.5}
                            flexWrap="nowrap"
                            sx={{ overflow: 'auto', maxWidth: '100%' }}>
                            {images.map((src, idx) => {
                                return (
                                    <CustomAvatar
                                        src={src}
                                        alt={src}
                                        variant="rounded"
                                        size={100}
                                        onClick={() => {
                                            setActiveStep(idx);
                                        }}
                                        styleProps={{
                                            '&.MuiAvatar-root': {
                                                ':hover': {
                                                    border: '1px solid darkblue',
                                                },
                                                border: `1px solid ${
                                                    activeStep === idx
                                                        ? 'darkblue'
                                                        : 'lightgrey'
                                                }`,
                                            },
                                            p: 2,
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                        }}
                                    />
                                );
                            })}
                        </Stack>
                    )}
                </Stack>
            }
        />
    );
}
function RenderProductImages({ title = '', images = [], ...props }) {
    const [activeStep, setActiveStep] = useState(0);
    const [openImagesDialog, setOpenImagesDialog] = useState(false);
    const handleNext = () => {
        if (activeStep === images.length - 1) return;
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) return;
        setActiveStep(activeStep - 1);
    };

    return (
        <Stack
            spacing={2}
            alignItems="center"
            pb={2}
            sx={{ position: '-webkit-sticky', position: 'sticky', top: 0 }}>
            <Stack
                direction={{ xs: 'column', lg: 'row' }}
                alignItems="flex-start"
                spacing={2}
                sx={{
                    maxHeight: '475px',
                    overflow: 'hidden',
                }}
                width={{ xs: 'auto', sm: '460px !important' }}>
                <Box
                    p={3}
                    sx={{
                        position: 'relative',
                        borderRadius: { xs: 0, lg: '15px' },
                        overflow: 'hidden',
                    }}>
                    {activeStep > 0 && (
                        <CustomButton
                            id={`arrow-left-custom-button-${
                                Math.random() * 1000
                            }`}
                            useIconButton
                            size="small"
                            variant="base"
                            onClick={handleBack}
                            styleProps={{
                                position: 'absolute',
                                left: 5,
                                border: 'none',
                                boxShadow: 1,
                                top: '50%',
                                zIndex: 1,
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                color: 'white',
                                ':hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                    border: 'none',
                                    color: 'white',
                                },
                                p: 0,
                            }}>
                            <KeyboardArrowLeftIcon fontSize="large" />
                        </CustomButton>
                    )}
                    {activeStep < images.length - 1 && (
                        <CustomButton
                            id={`arrow-right-custom-button-${
                                Math.random() * 1000
                            }`}
                            useIconButton
                            size="small"
                            variant="base"
                            onClick={handleNext}
                            styleProps={{
                                position: 'absolute',
                                right: 5,
                                border: 'none',
                                boxShadow: 1,
                                top: '50%',
                                zIndex: 1,
                                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                color: 'white',
                                ':hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                    border: 'none',
                                    color: 'white',
                                },
                                p: 0,
                            }}>
                            <KeyboardArrowRightIcon fontSize="large" />
                        </CustomButton>
                    )}
                    <AutoPlaySwipeableViews
                        interval={5000}
                        axis={'x'}
                        autoplay={false}
                        index={activeStep}
                        onChangeIndex={(index) => {
                            setActiveStep(index);
                        }}>
                        {images.map((src) => {
                            return (
                                <CustomAvatar
                                    key={Math.random() * 1000}
                                    variant="square"
                                    loading="lazy"
                                    styleProps={{
                                        width: '100%',
                                        height: 'auto',
                                        alignItems: 'flex-start',
                                    }}
                                    imgProps={{
                                        width: '100%',
                                        height: 'auto',
                                        'object-fit': 'contain',
                                    }}
                                    src={src}
                                    alt={
                                        'product-image-' + Math.random() * 1000
                                    }
                                />
                            );
                        })}
                    </AutoPlaySwipeableViews>
                </Box>
            </Stack>
            <Stack
                direction="row"
                justifyContent="center"
                spacing={1.5}
                flexWrap="nowrap"
                sx={{
                    overflow: 'hidden',
                    maxWidth: { xs: 'auto', sm: '460px' },
                }}>
                {(images.length >= 4
                    ? images.slice(0, 4)
                    : images.concat(['../assets/images/khanhtranglogo-02.png'])
                ).map((src, idx, arr) => {
                    return (
                        <Box
                            sx={{ position: 'relative' }}
                            key={Math.random() * 1000}>
                            <CustomAvatar
                                src={src}
                                alt={src}
                                variant="rounded"
                                onClick={() => {
                                    setActiveStep(idx);
                                }}
                                styleProps={{
                                    '&.MuiAvatar-root': {
                                        ':hover': {
                                            border: '1px solid darkblue',
                                        },
                                        border: `1px solid ${
                                            activeStep === idx ||
                                            (activeStep >= 3 && idx === 3)
                                                ? 'darkblue'
                                                : 'lightgrey'
                                        }`,
                                        ...(idx === arr.length - 1 && {
                                            opacity: 0.5,
                                        }),
                                    },
                                    p: 2,
                                    width: { xs: '70px', sm: '100px' },
                                    height: { xs: '70px', sm: '100px' },
                                    borderRadius: '8px',
                                    cursor: 'pointer',
                                }}
                            />
                            {idx === arr.length - 1 && (
                                <Stack
                                    width="100%"
                                    height="100%"
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        backgroundColor: 'rgba(0, 0 , 0, 0.35)',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setOpenImagesDialog(true)}
                                    alignItems="center"
                                    justifyContent="center">
                                    <Typography
                                        variant="caption"
                                        fontSize={14}
                                        fontWeight={500}
                                        color="white">
                                        {idx === 3 && images.length >= 4
                                            ? 'Xem thêm'
                                            : 'Xem ảnh'}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        fontSize={14}
                                        fontWeight={500}
                                        color="white">
                                        {idx === 3 && images.length >= 4
                                            ? `${images.length - 3} ảnh`
                                            : 'phóng to'}
                                    </Typography>
                                </Stack>
                            )}
                        </Box>
                    );
                })}
            </Stack>
            {openImagesDialog && (
                <WatchImagesCustomDialog
                    title={title}
                    images={images}
                    open={openImagesDialog}
                    onClose={() => {
                        setOpenImagesDialog(false);
                    }}
                />
            )}
        </Stack>
    );
}
function BoxDetails({
    title = '',
    author = 'Nhà thuốc Khánh Trang',
    content = [],
    caution = '',
    keywords = [],
    ...otherProps
}) {
    const navigate = useNavigate();
    const theme = useTheme();
    const biggerMD = useMediaQuery(theme.breakpoints.up('md')); // >= 800
    const [viewBiggerText, setViewBiggerText] = useState(false);
    const [isShowMore, setIsShowMore] = useState(false);
    const handleToggleMoreDetails = () => {
        setIsShowMore((v) => !v);
    };
    let headerContent = content
        .filter((i) => i.type === 'header1' && i.content)
        .map((i) => i.content);
    const [selectedPart, setSelectedPart] = useState(headerContent?.[0] || '');

    const scrollToPart = (content) => {
        const h1Elements = document.querySelectorAll('h1');
        for (let h1 of h1Elements) {
            if (h1.textContent.toLowerCase() === content.toLowerCase()) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                h1.scrollIntoView({ behavior: 'smooth' });
                setTimeout(() => {
                    h1.scrollIntoView({ behavior: 'smooth' });
                    if (!isShowMore) {
                        setSelectedPart(content);
                    }
                }, 100);

                break;
            }
        }
    };
    const scrollToTop = () => {
        const box = document.getElementById('box-content-info');
        if (box) {
            box.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    useEffect(() => {
        const handleScroll = () => {
            const h1Elements = document.querySelectorAll('h1');
            let found = false;

            for (let index = 0; index < h1Elements.length; index++) {
                const h1 = h1Elements[index];
                const nextH1 = h1Elements?.[index + 1] || null;

                const currentRect = h1.getBoundingClientRect();
                const offset = 10; // Adjust this value to control how far past the top the h1 needs to be

                // Condition 1: Current h1 is at or slightly past the top of the viewport
                if (currentRect.top <= offset && currentRect.bottom > offset) {
                    setSelectedPart(h1.textContent);
                    found = true;
                    break;
                }

                // Condition 2: Scrolling between this h1 and the next h1
                if (nextH1) {
                    const nextRect = nextH1.getBoundingClientRect();
                    if (currentRect.top < 0 && nextRect.top > offset) {
                        setSelectedPart(h1.textContent);
                        found = true;
                        break;
                    }
                }

                // Condition 3: Final h1 element
                if (!nextH1 && currentRect.top < 0) {
                    setSelectedPart(h1.textContent);
                    found = true;
                    break;
                }
            }

            if (!found) {
                setSelectedPart(headerContent?.[0] || '');
            }
        };
        if (isShowMore) {
            window.addEventListener('scroll', handleScroll);
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headerContent, isShowMore]);
    return (
        <Stack
            id="box-content-info"
            sx={{
                backgroundColor: 'white',
                borderRadius: { xs: 0, sm: '8px' },
                mt: 2,
            }}
            spacing={1}>
            {content.length === 1 && content[0].isUpdating ? (
                <Box sx={{ width: '100%' }}>
                    <Card
                        variant="outlined"
                        sx={{ width: '100%', border: 'none' }}>
                        <CardHeader
                            sx={{ px: 3, pb: 0 }}
                            title={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    <Typography
                                        component="h1"
                                        variant="h6"
                                        fontSize={
                                            biggerMD
                                                ? viewBiggerText
                                                    ? 30
                                                    : 28
                                                : viewBiggerText
                                                ? 22
                                                : 18
                                        }
                                        sx={{ mr: 'auto' }}
                                        fontWeight={700}>
                                        {title}
                                    </Typography>
                                    <Divider />
                                    <Stack
                                        alignItems="flex-start"
                                        spacing={0.5}
                                        py={1}>
                                        <Typography>
                                            Nội dung chi tiết đang được cập
                                            nhật...
                                        </Typography>
                                        {keywords.length > 0 && (
                                            <div>
                                                <Typography
                                                    fontSize={14}
                                                    fontWeight={500}
                                                    component="span">
                                                    Từ khóa:
                                                </Typography>
                                                {keywords.map(
                                                    (keyword, idx) => {
                                                        return (
                                                            <Typography
                                                                component="span"
                                                                onClick={() => {
                                                                    navigate(
                                                                        '/tim-kiem',
                                                                        {
                                                                            state: {
                                                                                filterBy:
                                                                                    [
                                                                                        'Sản phẩm',
                                                                                    ],
                                                                                searchData:
                                                                                    {
                                                                                        keyword:
                                                                                            keyword.trim(),
                                                                                    },
                                                                            },
                                                                        }
                                                                    );
                                                                }}
                                                                fontSize={14}
                                                                fontStyle={
                                                                    'italic'
                                                                }
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                textDecoration="none"
                                                                key={
                                                                    'keywords-' +
                                                                    keyword +
                                                                    '-' +
                                                                    idx
                                                                }
                                                                sx={{
                                                                    textTransform:
                                                                        'initial',
                                                                    color: 'blue',
                                                                    px: 0.25,
                                                                    ':hover': {
                                                                        backgroundColor:
                                                                            'transparent',
                                                                        color: 'black',
                                                                        textDecoration:
                                                                            'underline',
                                                                    },
                                                                }}>
                                                                {keyword.trim()}
                                                                {idx ===
                                                                keywords.length -
                                                                    1
                                                                    ? '.'
                                                                    : ','}
                                                            </Typography>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </Stack>
                                </Box>
                            }
                        />
                    </Card>
                </Box>
            ) : (
                <>
                    <Stack
                        direction="row"
                        alignItems="stretch"
                        justifyContent="space-between">
                        {headerContent.length > 0 && biggerMD && (
                            <Box p={3} sx={{ minHeight: '100%' }}>
                                <Box
                                    width="250px"
                                    sx={{
                                        border: '1px solid lightgrey',
                                        borderRadius: '12px',
                                        position: '-webkit-sticky',
                                        position: 'sticky',
                                        top: 10,
                                        overflow: 'hidden',
                                    }}>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="flex-start"
                                        spacing={1}
                                        p={1}
                                        pl={2}
                                        sx={{
                                            backgroundColor: '#EDEDED',
                                        }}>
                                        <FormatListBulletedIcon
                                            sx={{ color: 'grey' }}
                                        />
                                        <Typography
                                            color="grey"
                                            fontSize={14}
                                            fontWeight={600}>
                                            Nội dung chính
                                        </Typography>
                                    </Stack>
                                    <CustomList
                                        id="references-content-custom-list"
                                        sx={{ p: 0 }}
                                        options={headerContent.map(
                                            (content) => ({
                                                ListItemProps: {
                                                    sx: {
                                                        py: '10px',
                                                        backgroundColor:
                                                            selectedPart.toLowerCase() ===
                                                            content.toLowerCase()
                                                                ? 'aliceblue'
                                                                : 'transparent',
                                                        ':hover': {
                                                            backgroundColor:
                                                                selectedPart.toLowerCase() ===
                                                                content.toLowerCase()
                                                                    ? 'aliceblue'
                                                                    : 'transparent',
                                                            color: 'blue',
                                                        },
                                                    },
                                                },
                                                onClick: () => {
                                                    scrollToPart(content);
                                                },
                                                children: (
                                                    <Typography
                                                        sx={{
                                                            m: 0,
                                                            fontWeight:
                                                                selectedPart.toLowerCase() ===
                                                                content.toLowerCase()
                                                                    ? 600
                                                                    : 500,
                                                            fontSize: 14,
                                                        }}>
                                                        {content}
                                                    </Typography>
                                                ),
                                            })
                                        )}
                                        disablePaddingListItem
                                    />
                                </Box>
                            </Box>
                        )}
                        <Box sx={{ width: '100%' }}>
                            <Card
                                variant="outlined"
                                sx={{ width: '100%', border: 'none' }}>
                                <CardHeader
                                    sx={{
                                        px: 3,
                                        pb: 0,
                                    }}
                                    title={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}>
                                            <Typography
                                                component="h1"
                                                variant="h6"
                                                fontSize={
                                                    biggerMD
                                                        ? viewBiggerText
                                                            ? 30
                                                            : 28
                                                        : viewBiggerText
                                                        ? 22
                                                        : 18
                                                }
                                                sx={{ mr: 'auto' }}
                                                fontWeight={700}>
                                                {title}
                                            </Typography>
                                            <Divider />
                                            {content.length > 0 && (
                                                <Stack
                                                    direction={{
                                                        xs: 'column',
                                                        lg: 'row',
                                                    }}
                                                    alignItems={{
                                                        xs: 'flex-start',
                                                        lg: 'center',
                                                    }}
                                                    justifyContent={{
                                                        xs: 'flex-start',
                                                        lg: 'space-between',
                                                    }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="grey"
                                                        noWrap
                                                        fontSize={14}>
                                                        {`Người cập nhật: ${author}`}
                                                    </Typography>
                                                    <Stack
                                                        direction="row"
                                                        justifyContent={{
                                                            xs: 'space-between',
                                                            lg: 'flex-end',
                                                        }}
                                                        width={{
                                                            xs: '100%',
                                                            lg: 'auto',
                                                        }}
                                                        alignItems="center"
                                                        spacing={1}
                                                        mt={0.25}>
                                                        <Typography
                                                            fontSize={14}>
                                                            Kích thước chữ
                                                        </Typography>
                                                        <CustomToggleButton
                                                            id="font-size-custom-toggle-button"
                                                            sx={{
                                                                '&.MuiToggleButtonGroup-root':
                                                                    {
                                                                        borderRadius:
                                                                            '20px',
                                                                        alignItems:
                                                                            'center',
                                                                        border: '1px solid lightgrey',
                                                                        p: 0.25,
                                                                    },
                                                                '& .MuiToggleButtonGroup-grouped':
                                                                    {
                                                                        p: '1px',
                                                                        '&.Mui-disabled':
                                                                            {
                                                                                borderRadius: 0,
                                                                            },
                                                                        '&:first-of-type':
                                                                            {
                                                                                borderRadius:
                                                                                    '20px',
                                                                                border: 'none',
                                                                                backgroundColor:
                                                                                    !viewBiggerText
                                                                                        ? 'rgba(0,0,139,0.75)'
                                                                                        : 'transparent',
                                                                                p: 0.25,
                                                                                minWidth: 40,
                                                                                m: 0.1,
                                                                            },
                                                                        '&:not(:first-of-type)':
                                                                            {
                                                                                borderRadius:
                                                                                    '20px',
                                                                                border: 'none',
                                                                                backgroundColor:
                                                                                    viewBiggerText
                                                                                        ? 'rgba(0,0,139,0.75)'
                                                                                        : 'transparent',
                                                                                p: 0.25,
                                                                                minWidth: 40,
                                                                                m: 0.1,
                                                                            },
                                                                    },
                                                            }}
                                                            options={[
                                                                {
                                                                    value: false,
                                                                    icon: (
                                                                        <CustomAvatar
                                                                            variant="rounded"
                                                                            styleProps={{
                                                                                fontSize: 12,
                                                                                width: 60,
                                                                                heigth: 10,
                                                                                backgroundColor:
                                                                                    'transparent',
                                                                                color: !viewBiggerText
                                                                                    ? 'white'
                                                                                    : 'black',
                                                                                textTransform:
                                                                                    'initial',
                                                                            }}>
                                                                            Mặc
                                                                            định
                                                                        </CustomAvatar>
                                                                    ),
                                                                },
                                                                {
                                                                    value: true,
                                                                    icon: (
                                                                        <CustomAvatar
                                                                            variant="rounded"
                                                                            styleProps={{
                                                                                fontSize: 12,
                                                                                width: 60,
                                                                                heigth: 10,
                                                                                backgroundColor:
                                                                                    'transparent',
                                                                                color: viewBiggerText
                                                                                    ? 'white'
                                                                                    : 'black',
                                                                                textTransform:
                                                                                    'initial',
                                                                            }}>
                                                                            Lớn
                                                                            hơn
                                                                        </CustomAvatar>
                                                                    ),
                                                                },
                                                            ]}
                                                            value={
                                                                viewBiggerText
                                                            }
                                                            onChange={() => {
                                                                setViewBiggerText(
                                                                    (
                                                                        prevValue
                                                                    ) =>
                                                                        !prevValue
                                                                );
                                                            }}
                                                            exclusive
                                                            size="small"
                                                        />
                                                    </Stack>
                                                </Stack>
                                            )}
                                        </Box>
                                    }
                                />
                                <Box sx={{ px: { xs: 2, sm: 0 } }}>
                                    <PageItemDetails
                                        newContent={content}
                                        toggleMoreDetails={isShowMore}
                                        keywordArr={keywords}
                                        viewBiggerText={viewBiggerText}
                                    />
                                </Box>
                            </Card>
                            {content.length > 0 && (
                                <Box
                                    sx={{
                                        p: 0,
                                        position: 'relative',
                                    }}>
                                    {isShowMore ? (
                                        <CustomButton
                                            onClick={() => {
                                                scrollToTop();
                                                if (headerContent.length > 0) {
                                                    setSelectedPart(
                                                        headerContent[0]
                                                    );
                                                }
                                                handleToggleMoreDetails();
                                            }}
                                            id="show-less-details-custom-button"
                                            variant="base"
                                            size="small"
                                            fullWidth
                                            styleProps={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                backgroundColor: 'transparent',
                                                '&:hover': {
                                                    backgroundColor:
                                                        'transparent',
                                                },
                                            }}
                                            startIcon={
                                                <KeyboardDoubleArrowDownIcon
                                                    sx={{
                                                        transform:
                                                            'rotate(180deg)',
                                                    }}
                                                />
                                            }>
                                            Thu gọn
                                        </CustomButton>
                                    ) : (
                                        <CustomButton
                                            onClick={() => {
                                                handleToggleMoreDetails();
                                                if (headerContent.length > 0) {
                                                    scrollToPart(selectedPart);
                                                }
                                            }}
                                            id="show-more-details-custom-button"
                                            variant="base"
                                            size="small"
                                            fullWidth
                                            styleProps={{
                                                position: 'absolute',
                                                bottom: 0,
                                                background:
                                                    'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 21.88%, rgba(255, 255, 255, 0.95) 45.31%, #fff 67.71%, #fff 100%)',
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                height: '68px',
                                                alignItems: 'flex-end',
                                                backgroundColor: 'transparent',
                                                '&:hover': {
                                                    backgroundColor:
                                                        'transparent',
                                                },
                                            }}
                                            startIcon={
                                                <KeyboardDoubleArrowDownIcon />
                                            }>
                                            Xem thêm
                                        </CustomButton>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Stack>
                    {caution && (
                        <Box py={2} px={3}>
                            <Box
                                sx={{
                                    borderLeft: '4px solid rgba(0,0,139,0.75)',
                                    backgroundColor: 'aliceblue',
                                    borderRadius: '4px',
                                    width: '100%',
                                    py: 0.5,
                                    pl: 1,
                                }}>
                                <Typography
                                    variant="caption"
                                    fontWeight={600}
                                    color="blue">
                                    {caution}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </Stack>
    );
}
export default function PageProductItem({ ...otherProps }) {
    const { itemSlug } = useParams();
    const { itemData } = useLoaderData();
    const [tooltipOpen, setTooltipOpen] = useState(false);

    if (itemSlug && !itemData) {
        return <Page404 />;
    }
    let breadcrumbsData = itemData.category
        .map((i) => ({
            to: i.slug,
            name: i.name,
        }))
        .concat([
            { to: itemData.slug, name: itemData.shortName, disabled: true },
        ]);

    let StyledTypography = ({ children, ...otherProps }) => {
        return (
            <Typography
                fontSize={{ xs: 14, sm: 16 }}
                fontWeight={500}
                sx={{ minWidth: { xs: 100, sm: 140 } }}
                {...otherProps}>
                {children}
            </Typography>
        );
    };
    const handleCopySku = async (data) => {
        try {
            await navigator.clipboard.writeText(data);
            setTooltipOpen(true);
            setTimeout(() => {
                setTooltipOpen(false);
            }, 3000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };
    let caution =
        itemData.category?.[1]?.id === '01-1'
            ? 'Thuốc kê đơn chỉ bán khi có chỉ định của bác sĩ, mọi thông tin trên Website chỉ mang tính chất tham khảo.'
            : itemData.category?.[1]?.id === '01-2'
            ? 'Thuốc nên được sử dụng theo chỉ định của bác sĩ, mọi thông tin trên Website chỉ mang tính chất tham khảo.'
            : itemData.category?.[1]?.id?.startsWith('02')
            ? 'Thực phẩm bảo vệ sức khoẻ, không phải là thuốc, không có tác dụng thay thế thuốc chữa bệnh.'
            : '';
    return (
        <>
            <CustomHelmet
                slug={`https://nhathuockhanhtrang.com.vn${itemData.slug}`}
                title={itemData.shortName + ' - Nhà thuốc Khánh Trang'}
                description={itemData.webName}
                image={itemData.primaryImage.mainSrc}
            />
            <Stack sx={{ maxWidth: 1260, mx: 'auto', px: { xs: 0, sm: 2 } }}>
                <Stack spacing={2.5} my={2.5}>
                    <Box pl={{ xs: 2, sm: 0 }}>
                        <SectionBreadcrumbs breadcrumbsData={breadcrumbsData} />
                    </Box>
                    <Box maxWidth="100%" pl="16px" pt={2}>
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                backgroundColor: 'white',
                                borderRadius: { xs: 0, sm: '8px' },
                            }}>
                            <Grid item xs={12} lg={5}>
                                <RenderProductImages
                                    title={itemData.webName}
                                    images={itemData.secondaryImages
                                        .filter((i) => i.isActive && i.status)
                                        .map((i) => i.mainSrc)}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                lg
                                sx={{ maxWidth: 'calc(100% - 16px)', pb: 2 }}>
                                <Box sx={{ my: 1, pl: 1 }}>
                                    <Typography
                                        color="blue"
                                        variant="subtitle2">
                                        Sản phẩm cần tư vấn từ dược sỹ.
                                    </Typography>
                                </Box>
                                <Typography
                                    color="black"
                                    variant="h1"
                                    fontSize={{ xs: '16px', sm: '24px' }}
                                    lineHeight={{ xs: '1.5rem', sm: '2.25rem' }}
                                    letterSpacing="0.01em"
                                    fontWeight={500}
                                    sx={{ pl: 1, pr: 2 }}>
                                    {itemData.webName}
                                </Typography>
                                <Stack
                                    sx={{ my: 1, pl: 1 }}
                                    direction={{ xs: 'colum', sm: 'row' }}
                                    alignItems={{
                                        xs: 'flex-start',
                                        sm: 'center',
                                    }}
                                    justifyContent="flex-start">
                                    <Box>
                                        <Tooltip
                                            title="Đã sao chép vào bộ nhớ tạm"
                                            placement="top"
                                            open={tooltipOpen}
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [
                                                                    0, -14,
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            }}>
                                            <span>
                                                <CustomButton
                                                    id="sku-copy-custom-button"
                                                    variant="base"
                                                    size="small"
                                                    styleProps={{
                                                        minWidth: 0,
                                                        p: 0,
                                                        color: 'black',
                                                        backgroundColor:
                                                            'transparent',
                                                        ':hover': {
                                                            color: 'lightgrey',
                                                            backgroundColor:
                                                                'transparent',
                                                        },
                                                        fontSize: '16px',
                                                        fontWeight: 400,
                                                    }}
                                                    endIcon={
                                                        <ContentCopyIcon
                                                            sx={{
                                                                width: 16,
                                                                height: 16,
                                                            }}
                                                        />
                                                    }
                                                    onClick={() =>
                                                        handleCopySku(
                                                            itemData.sku
                                                        )
                                                    }>
                                                    {itemData.sku}
                                                </CustomButton>
                                            </span>
                                        </Tooltip>
                                        <Typography
                                            variant="caption"
                                            color="grey">
                                            {' • '}
                                        </Typography>
                                        <Typography variant="caption">
                                            {itemData.isActive
                                                ? 'Đang kinh doanh'
                                                : 'Ngừng kinh doanh'}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant="caption"
                                            color="grey">
                                            {' • '}
                                        </Typography>
                                        <Typography variant="caption">
                                            {'Cập nhật: ' +
                                                moment(
                                                    itemData.modifiedAt
                                                ).format('DD-MM-YYYY')}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Box pr={3}>
                                    <CustomTable
                                        rows={[
                                            [
                                                <b>Giá tham khảo</b>,
                                                <span>
                                                    <b>{'Liên hệ Zalo: '}</b>
                                                    <a
                                                        href="tel:0704430919"
                                                        style={{
                                                            color: 'blue',
                                                            textDecoration:
                                                                'none',
                                                        }}>
                                                        070.443.0919
                                                    </a>
                                                </span>,
                                            ],
                                            [
                                                'Danh mục',
                                                itemData.category[
                                                    itemData.category.length - 1
                                                ].name,
                                            ],
                                            [
                                                'Dạng bào chế',
                                                itemData.dosageForm.join(', '),
                                            ],
                                            [
                                                'Quy cách',
                                                itemData.specification,
                                            ],
                                            [
                                                'Thành phần',
                                                itemData.ingredients_raw,
                                            ],
                                            ['Công dụng', itemData.mainUse],
                                            [
                                                'Nhà sản xuất',
                                                itemData.manuValue.manuName,
                                            ],
                                            [
                                                'Nước sản xuất',
                                                itemData.manuValue.manuNation,
                                            ],
                                            [
                                                'Mô tả sản phẩm',
                                                itemData.attribute,
                                            ],
                                            ['Số đăng ký', itemData.licenses],
                                            [
                                                'Thuốc cần kê toa',
                                                itemData.category?.[1]?.id?.startsWith(
                                                    '01-1'
                                                )
                                                    ? 'Có'
                                                    : itemData.category?.[1]?.id?.startsWith(
                                                          '01-2'
                                                      )
                                                    ? 'Không'
                                                    : '',
                                            ],
                                            [<b>Lưu ý</b>, <b>{caution}</b>],
                                        ]
                                            .filter((i) => !!i[1])
                                            .map((rowData, i) => ({
                                                cells: rowData.map(
                                                    (cellData, j) => ({
                                                        label:
                                                            i === 1 &&
                                                            j === 1 ? (
                                                                <Link
                                                                    component={
                                                                        RouterLink
                                                                    }
                                                                    underline="none"
                                                                    sx={{
                                                                        color: 'blue',
                                                                    }}
                                                                    to={
                                                                        itemData
                                                                            .category[0]
                                                                            .slug +
                                                                        (itemData
                                                                            .category
                                                                            .length >
                                                                        1
                                                                            ? itemData
                                                                                  .category[
                                                                                  itemData
                                                                                      .category
                                                                                      .length -
                                                                                      1
                                                                              ]
                                                                                  .slug
                                                                            : '')
                                                                    }>
                                                                    <StyledTypography>
                                                                        {
                                                                            cellData
                                                                        }
                                                                    </StyledTypography>
                                                                </Link>
                                                            ) : i === 6 &&
                                                              j === 1 ? (
                                                                <Link
                                                                    component={
                                                                        RouterLink
                                                                    }
                                                                    underline="none"
                                                                    sx={{
                                                                        color: 'blue',
                                                                    }}
                                                                    to={
                                                                        '/tim-kiem?keyword=' +
                                                                        encodeURIComponent(
                                                                            itemData
                                                                                .manuValue
                                                                                .manuName
                                                                        )
                                                                    }>
                                                                    <StyledTypography>
                                                                        {
                                                                            cellData
                                                                        }
                                                                    </StyledTypography>
                                                                </Link>
                                                            ) : (
                                                                <StyledTypography>
                                                                    {cellData}
                                                                </StyledTypography>
                                                            ),
                                                        sx: {
                                                            borderBottom: {
                                                                sm: 'none',
                                                            },
                                                            padding: {
                                                                xs: 0.5,
                                                                sm: 1,
                                                            },
                                                        },
                                                    })
                                                ),
                                                styleProps: {
                                                    verticalAlign: 'top',
                                                },
                                            }))}
                                        useStripedRows={false}
                                    />
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    backgroundColor: 'aliceblue',
                                    display: { xs: 'none', sm: 'block' },
                                }}>
                                <Commitment />
                            </Grid>
                        </Grid>
                    </Box>
                    <BoxDetails
                        content={JSON.parse(itemData.contentInfo)}
                        title={itemData.shortName}
                        keywords={itemData.keywords
                            .filter((i) => i.status)
                            .map((i) => i.keyName)}
                        caution={caution}
                    />
                    <SimilarProd
                        subCategoryPath={
                            itemData.category[itemData.category.length - 1].slug
                        }
                    />
                    <RecentWatchProd />
                </Stack>
            </Stack>
        </>
    );
}

export const loader = async ({ params }) => {
    let { itemSlug } = params;
    if (itemSlug) {
        let response = await fetch(
            domain + '/product-item/data?itemSlug=' + itemSlug.replace('/', '')
        );
        if (!response.ok) {
            return { itemData: null };
        }
        let data = await response.json();
        return { itemData: data.data };
    }

    return { itemData: null };
};
